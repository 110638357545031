import React from 'react'
import { Button } from 'reactstrap'

import LoadingGif from 'components/Common/LoadingGif'

const SubmitButton = ({
  name,
  disabled,
  value = '',
  color = 'primary',
  loading,
  className,
  ...restProps
}) => {
  return (
    <Button
      type="submit"
      className={className}
      name={name}
      color={color}
      disabled={loading || disabled}
      {...restProps}
    >
      {value}
      <LoadingGif loading={loading} />
    </Button>
  )
}

export default SubmitButton
