import React from 'react'
import { Link } from 'react-router-dom'
import Yup from 'yup'
import { withFormik } from 'formik'

import Input from 'components/Common/Input'
import ServerErrors from 'components/Common/ServerErrors'
import SubmitButton from 'components/Common/SubmitButton'
import { URLS } from 'config/urls'
import s from './Login.module.css'

const Login = ({ onChange, values, touched, errors, serverErrors, isSubmitting, handleSubmit }) => (
  <div className={s.root}>
    <div className={s.container}>
      <div className={s.row}>
        <div className={s.card}>
          <div className={s.card__body}>
            <form className={s.loginForm} onSubmit={handleSubmit}>
              <h1 className={s.loginForm__title}>Log In</h1>
              <div className={s.loginForm__input}>
                <Input
                  value={values.username}
                  name="username"
                  placeholder="Username"
                  type="text"
                  onChange={onChange}
                  className={s['form-control']}
                  errors={errors}
                  touched={touched}
                  serverErrors={serverErrors}
                />
              </div>
              <div className={s.loginForm__input}>
                <Input
                  value={values.password}
                  name="password"
                  type="password"
                  placeholder="Password"
                  onChange={onChange}
                  className={s['form-control']}
                  errors={errors}
                  touched={touched}
                  serverErrors={serverErrors}
                />
              </div>
              <SubmitButton value="Log In" loading={isSubmitting} />
              <ServerErrors errors={serverErrors} />
            </form>
          </div>
        </div>
      </div>
      <div className={s.row}>
        <div className={s.cardBottomContainer}>
          <Link to={URLS.AUTH.RESET_PASSWORD} className={s.authLink}>
            Forgot your password?
          </Link>
          <Link to={URLS.AUTH.SIGNUP} className={s.authLink}>
            Create a new account
          </Link>
        </div>
      </div>
    </div>
  </div>
)

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({
    username: props.username,
    password: props.password,
    errors: props.serverErrors,
  }),
  validationSchema: Yup.object().shape({
    username: Yup.string().required('Username field is required!'),
    password: Yup.string().required('Password field is required!'),
  }),

  // eslint-disable-next-line
  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit().then(success => {
      setSubmitting(false)
      if (success) {
        window.location.replace(URLS.APPS.HOMEPAGE)
      }
    })
  },
})(Login)
