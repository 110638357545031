import React from 'react'
import { Redirect } from 'react-router-dom'

import { URLS } from 'config/urls'
import { authenticate, setIsSocialUser, deleteIsSocialUser } from 'utils/authService'
import { getLoginRedirectUrl, deleteLoginRedirectUrl } from 'utils/authService'

const OauthLoginSuccess = () => {
  const params = new URL(document.location).searchParams
  const token = params.get('token')
  const isSocialUser = params.get('is_social_user')
  authenticate(token)
  let redirectUrl = '/'
  if (isSocialUser) {
    redirectUrl = URLS.REQUESTED_PRODUCTS.HOMEPAGE
    setIsSocialUser()
  } else {
    // Delete social user in case they previously logged in
    deleteIsSocialUser()
    redirectUrl = getLoginRedirectUrl()
    deleteLoginRedirectUrl()
  }

  return (
    <Redirect
      to={{
        pathname: redirectUrl,
        state: { firstLogin: true },
      }}
    />
  )
}

export default OauthLoginSuccess
