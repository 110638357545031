import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/browser'

import { SENTRY_DSN } from 'config/config'
import client from 'config/configureClient'
import ScrollToTop from 'components/Common/ScrollToTop'
import App from 'components/Base/App'
import 'index.css'
import { unregister } from './registerServiceWorker'

if (SENTRY_DSN) {
  Sentry.init({ dsn: SENTRY_DSN })
}

const renderApp = Component => {
  render(
    <ApolloProvider client={client}>
      <Router>
        <ScrollToTop>
          <Component />
        </ScrollToTop>
      </Router>
    </ApolloProvider>,
    document.getElementById('root'),
  )
}

unregister()
renderApp(App)

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('components/Base/App', () => {
    renderApp(App)
  })
}
