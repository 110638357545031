import React, { useEffect } from 'react'
import { useLocation, Redirect, Route } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { loader as gqlLoader } from 'graphql.macro'
import querystring from 'query-string'

import Loader from 'components/Common/Loader'
import { getBackendEndpoint, DRCHRONO_AUTHORIZE_URL } from 'config/config'
import { URLS } from 'config/urls'
import {
  clearUTMParams,
  isDrChronoAuthenticated,
  isSocialAuthenticated,
  isAuthenticated,
  setUTMParams,
} from 'utils/authService'

const CURRENT_USER = gqlLoader('graphql/queries/CurrentUser.graphql')
const CURRENT_SOCIAL_USER = gqlLoader('graphql/queries/CurrentSocialUser.graphql')

const AuthRoute = ({
  component: Component,
  layout: Layout,
  layoutProps,
  componentProps,
  authRequired,
  ...restProps
}) => {
  const location = useLocation()

  useEffect(() => {
    const {
      utm_medium,
      utm_campaign,
      utm_source,
      utm_content,
      utm_term,
      utm_referral_uuid,
    } = querystring.parse(location.search)
    if (utm_medium || utm_campaign || utm_source || utm_content || utm_term) {
      clearUTMParams()
      setUTMParams({
        utm_medium,
        utm_campaign,
        utm_source,
        utm_content,
        utm_term,
        utm_referral_uuid,
      })
    }
  })

  if (querystring.parse(location.search).logged_in && !isDrChronoAuthenticated()) {
    window.location.replace(
      `${DRCHRONO_AUTHORIZE_URL}&next=${getBackendEndpoint(location.pathname)}`,
    )
  }

  const { loading: loadingCurrentUser, data: userData } = useQuery(CURRENT_USER, {
    skip: !isDrChronoAuthenticated(),
  })
  const { loading: loadingCurrentSocialUser } = useQuery(CURRENT_SOCIAL_USER, {
    skip: !isSocialAuthenticated(),
  })

  return (
    <Route
      {...restProps}
      render={routeProps => {
        const { pathname } = routeProps.location
        if (authRequired && !isAuthenticated()) {
          return (
            <Redirect
              to={{
                pathname: URLS.AUTH.LOGIN,
                state: {
                  from: pathname === URLS.AUTH.LOGOUT ? URLS.APPS.HOMEPAGE : routeProps.location,
                },
              }}
            />
          )
        }

        let currentUser = null
        if (isDrChronoAuthenticated()) {
          if (loadingCurrentUser) {
            return <Loader />
          }
          // if (pathname.startsWith(DEV)) {
          //   if (pathname === URLS.DEV.TOS && currentUser.devTosSigned) {
          //     return <Redirect to={URLS.DEV.APPS} />
          //   }
          //   if (pathname !== URLS.DEV.TOS && !currentUser.devTosSigned) {
          //     return <Redirect to={URLS.DEV.TOS} />
          //   }
          // }
          currentUser = userData.currentUser
        }
        if (isSocialAuthenticated()) {
          if (loadingCurrentSocialUser) {
            return <Loader />
          }
        }
        return (
          <Layout {...layoutProps} currentUser={currentUser}>
            <Component {...routeProps} {...componentProps} currentUser={currentUser} />
          </Layout>
        )
      }}
    />
  )
}

export default AuthRoute
