import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { URLS } from 'config/urls'
import s from './DevSidebarContent.module.css'

const makeRoutes = () => {
  // if (!devTosSigned) {
  //   return [{ path: URLS.DEV.TOS, label: 'Terms of Service' }]
  // }
  const routes = [
    {
      path: URLS.DEV.APPS,
      label: 'My Apps',
    },
    {
      path: URLS.DEV.SERVICES,
      label: 'My Services',
    },
    {
      path: URLS.DEV.MY_TEAM,
      label: 'My Team',
    },
    // {
    //   path: URLS.DEV.DOCUMENTS,
    //   label: 'Documents',
    // },
    {
      path: URLS.DEV.VIEWS,
      label: 'Views',
    },
    {
      path: URLS.DEV.LEADS,
      label: 'Marketplace Leads',
      separator: true,
    },
    {
      path: URLS.DEV.REFERRALS,
      label: 'Marketplace Referrals',
    },
    {
      path: URLS.DEV.LEADS_REPORTING,
      label: 'Lead Status Reporting',
      separator: true,
    },
    {
      path: URLS.DEV.CUSTOMER_REPORTING,
      label: 'Customer Status Reporting',
      separator: true,
    },
    {
      path: URLS.DEV.HELP,
      label: 'Help',
      separator: true,
    },
  ]
  return routes
}

const DevSidebarContent = ({ activeRoute, onClick }) => {
  return (
    <div className={s.sidebarContent}>
      <h4>Settings</h4>
      <div className={s.sidebarContentItem}>
        {makeRoutes().map(route => (
          <div key={route.path}>
            {route.separator && <hr />}
            <NavLink
              activeClassName={cn({ [s.active]: activeRoute(route.path) })}
              to={route.path}
              onClick={onClick}
            >
              {route.label}
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  )
}

export default DevSidebarContent
