import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { useApolloClient, useMutation } from '@apollo/client'
import { loader as gqlLoader } from 'graphql.macro'

import { URLS } from 'config/urls'
import { isAuthenticated, logout as logoutAuth } from 'utils/authService'

const LOGOUT = gqlLoader('graphql/mutations/Logout.graphql')

const LogoutContainer = () => {
  const client = useApolloClient()
  const [logout] = useMutation(LOGOUT)
  console.log('render')

  useEffect(() => {
    const handleLogout = async () => {
      const result = await logout()
      console.log(result)
      if (!result.data.logout.errors) {
        logoutAuth(client)
      }
    }
    if (isAuthenticated()) {
      handleLogout()
    }
  }, [client, logout])
  return <Redirect to={URLS.APPS.HOMEPAGE} />
}

export default LogoutContainer
