import React from 'react'

import Footer from 'components/Base/Footer'
import Header from 'components/Base/Header'

const Layout = ({ oauthDenied, children }) => {
  return (
    <>
      <Header oauthDenied={oauthDenied} />
      {children}
      <Footer />
    </>
  )
}

export default Layout
