import React, { useContext, useState } from 'react'
import cn from 'classnames'

import ScreenResolutionContext from 'utils/ScreenResolutionContext'
import burgerMenuIcon from 'assets/images/burger-menu-icon-white.svg'
import burgerMenuCloseIcon from 'assets/images/burger-menu-close-icon.svg'
import HeaderSearchContainer from 'containers/HeaderSearchContainer'
import s from './Header.module.css'
import HeaderLinks from './HeaderLinks'
import MainNavigation from './MainNavigation'
import logoImage from 'assets/logos/drchrono-white.png'

const Header = ({ oauthDenied, showNavigation = true }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const screenResolution = useContext(ScreenResolutionContext)

  return (
    <header className={s.root}>
      <div className={s.container}>
        <div className={s.brandContainer}>
          <a href="https://www.drchrono.com" className={s.brand}>
            <img className={s.logo} src={logoImage} alt="drchrono" />
          </a>
        </div>
        <div className={s.rightContainer}>
          <HeaderSearchContainer />
          <HeaderLinks oauthDenied={oauthDenied} />
          <button className={s.navMobileToggle} onClick={() => setShowMobileMenu(!showMobileMenu)}>
            <img className={s.navMobileToggleOpen} src={burgerMenuIcon} alt="mobile menu icon" />
          </button>
        </div>
      </div>
      {/* Only display the navigation links on header on mobile resolution */}
      {showNavigation && !screenResolution.desktop ? <MainNavigation /> : null}
      {showMobileMenu && (
        <div className={s.navBarMobile}>
          <button
            className={cn(s.navMobileToggle, s.closeButton)}
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <img
              className={s.navMobileToggleClose}
              src={burgerMenuCloseIcon}
              alt="mobile menu close icon"
            />
          </button>
          <HeaderLinks
            oauthDenied={oauthDenied}
            isMobile
            toggleMobileMenu={() => setShowMobileMenu(!showMobileMenu)}
          />
        </div>
      )}
    </header>
  )
}

export default Header
