import React from 'react'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroller'

import Loader from 'components/Common/Loader'

import s from './Card.module.css'

const CardsList = ({ children, className, infiniteScroll }) => {
  return (
    <div className={cn(s.cardsList, className)}>
      {infiniteScroll ? (
        <InfiniteScroll
          className={s.cardsListContent}
          pageStart={infiniteScroll.pageStart}
          loadMore={infiniteScroll.loadMore}
          hasMore={infiniteScroll.hasMore}
          loader={<Loader />}
        >
          {children}
        </InfiniteScroll>
      ) : (
        <div className={s.cardsListContent}>{children}</div>
      )}
    </div>
  )
}

export default CardsList
