import { getBackendEndpoint } from 'config/config'
import { getToken } from 'utils/authService'
import { PRODUCT_TYPE } from 'utils/constants'

export const AUTH = '/auth'
export const DEV = '/dev'
export const APP = '/app'
export const APPS = '/apps'
export const SERVICE = '/service'
export const SERVICES = '/services'
export const RESOURCE = '/resource'
export const RESOURCES = '/resources'
export const REQUESTED_PRODUCT = '/partner-hunt'
export const BECOME_PARTNER = '/become-a-partner'
export const NETWORK = '/network'
export const URLS = {
  APPS: {
    HOMEPAGE: '/',
    CATEGORY: `${APPS}/category/:uuid/:slug`,
    DETAILS: `${APP}/:uuid/:slug`,
  },
  SERVICES: {
    HOMEPAGE: SERVICES,
    CATEGORY: `${SERVICES}/category/:uuid/:slug`,
    DETAILS: `${SERVICE}/:uuid/:slug`,
  },
  RESOURCES: {
    HOMEPAGE: RESOURCES,
    DETAILS: `${RESOURCE}/:uuid`,
  },
  REQUESTED_PRODUCTS: {
    HOMEPAGE: REQUESTED_PRODUCT,
    DETAILS: `${REQUESTED_PRODUCT}/:uuid/:slug`,
  },
  BECOME_PARTNER: {
    HOMEPAGE: BECOME_PARTNER,
  },
  AUTH: {
    OAUTH_LOGIN_SUCCESS: '/oauth/login/success',
    OAUTH_LOGIN_DENIED: '/oauth/login/denied',
    LOGIN: `${AUTH}/login`,
    LOGOUT: `${AUTH}/logout`,
    SIGNUP: `${AUTH}/signup`,
    RESET_PASSWORD: `${AUTH}/reset-password`,
    SET_PASSWORD: `${AUTH}/set-new-password`,
    CHANGE_PASSWORD: `${AUTH}/change-password`,
  },
  DEV: {
    TOS: `${DEV}/tos`,
    APPS: `${DEV}/apps`,
    APP: `${DEV}/app/:uuid`,
    SERVICES: `${DEV}/services`,
    SERVICE: `${DEV}/service/:uuid`,
    DOCUMENTS: `${DEV}/documents`,
    VIEWS: `${DEV}/views`,
    LEADS: `${DEV}/leads`,
    LEADS_REPORTING: `${DEV}/leads/reporting`,
    CUSTOMER_REPORTING: `${DEV}/customer/reporting`,
    REFERRALS: `${DEV}/referrals`,
    MY_TEAM: `${DEV}/team`,
    HELP: `${DEV}/help`,
  },
  MY_PROFILE: '/my-profile',
  DOCTORS: {
    HOMEPAGE: '/today',
    DOCTOR: `${NETWORK}/:uuid/:name`,
  },
}

export const LEADS_DOWNLOAD = `${getBackendEndpoint('/backend/leads/download')}?token=${getToken()}`
export const productPath = ({ productType, uuid, slug }) =>
  `${productType === PRODUCT_TYPE.APP ? APP : SERVICE}/${uuid}/${slug}`
export const requestedProductPath = ({ uuid, slug }) => `${REQUESTED_PRODUCT}/${uuid}/${slug}`
export const categoryPath = (c, pathname) =>
  `${pathname.startsWith(SERVICES) ? SERVICES : APPS}/category/${c.uuid}/${c.slug}`
export const resourcePath = r => `${RESOURCE}/${r.uuid}`
export const devProductPath = ({ productType, uuid }) =>
  `${DEV}${productType === PRODUCT_TYPE.APP ? APP : SERVICE}/${uuid}`

// in sync with the backend
export const userNetworkPath = ({ uuid, firstName, lastName }) =>
  `${NETWORK}/${uuid}/${firstName}-${lastName}`
