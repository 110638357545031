import web from 'assets/images/platforms/web-black-circle.svg'
import android from 'assets/images/platforms/android-black-circle.svg'
import appletv from 'assets/images/platforms/appletv-black-circle.svg'
import applewatch from 'assets/images/platforms/applewatch-black-circle.svg'
import iPhone from 'assets/images/platforms/iPhone-black-circle.svg'
import iPad from 'assets/images/platforms/iPad-black-circle.svg'
import others from 'assets/images/platforms/others-black-circle.svg'

export const emailRegex = /\S+@\S+/
export const phoneRegex = /^(\+?\d{0,4})\d{6,8}$/
export const zipcodeRegex = /^\d{5}(-\d{4})?$/

export const MAX_CATEGORIES = 2
export const MAX_SUBCATEGORIES = 3
export const MAX_IMAGES = 5
export const MAX_DOCUMENTS = 5

// min and max size for logo images (available for both width and height)
export const IMG_LOGO_SIZE_MIN = 512
export const IMG_LOGO_SIZE_MAX = 2000

// min and max size for regular images (available for both width and height), in sync with backend
export const IMG_SIZE_MIN_WIDTH = 300
export const IMG_SIZE_MIN_HEIGHT = 200
export const IMG_SIZE_MAX_WIDTH = 2000
export const IMG_SIZE_MAX_HEIGHT = 2000

// Size for homepage image should match backend
export const IMG_HOMEPAGE_WIDTH = 930
export const IMG_HOMEPAGE_HEIGHT = 300

export const PLATFORMS = [
  ['WEB', 'Web', web],
  ['IPAD', 'iPad', iPad],
  ['IPHONE', 'iPhone', iPhone],
  ['ANDROID', 'Android', android],
  ['APPLE_WATCH', 'Apple Watch', applewatch],
  ['APPLE_TV', 'Apple TV', appletv],
  ['OTHER', 'Other', others],
].map(([value, label, image]) => ({
  value,
  label,
  image,
}))

export const PRODUCT_STATUS_COLOR = {
  SINGLE: 'secondary',
  REVIEW: 'warning',
  REJECTED: 'danger',
  NOTARIZED: 'primary',
  READY: 'primary',
  LIVE: 'success',
  DEV_REJECTED: 'dark',
}

export const PRODUCT_STATUS_LABEL = {
  SINGLE: 'Single',
  REVIEW: 'Review',
  REJECTED: 'Rjeected',
  NOTARIZED: 'Notarized',
  READY: 'Read',
  LIVE: 'Live',
  DEV_REJECTED: 'Dev Rejected',
}

export const ProductStatus = {
  SINGLE: 'SINGLE',
  REVIEW: 'REVIEW',
  NOTARIZED: 'NOTARIZED',
  READY: 'READY',
  LIVE: 'LIVE',
  DEV_REJECTED: 'DEV_REJECTED',
}

export const ProductStatusAction = {
  REVIEW: 'review',
  WITHDRAW: 'withdraw',
  RESTORE: 'restore',
  DELETE: 'delete',
  PUBLISH: 'publish',
  UNPUBLISH: 'unpublish',
}

export const YES_NO_OPTIONS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

export const PRODUCT_TYPE = {
  APP: 'APP',
  SERVICE: 'SERVICE',
}

export const TITLES = [
  ['DrChrono Employee', 'drc-e', 'DRCHRONO_EMPLOYEE'],
  ['Non-MD Healthcare Provider', 'non-md', 'NONMD'],
  ['Medical Phyisician', 'medical-p', 'MEDICAL_PHYSICIAN'],
  ['Partner', 'partner', 'PARTNER'],
  ['Practice Administrator', 'practice-admin', 'PRACTICE_ADMINISTRATOR'],
  ['Other', 'other', 'OTHER'],
].map(([label, value, enumValue]) => ({
  label,
  value,
  enumValue,
}))

export const GENDERS = [
  ['Male', 'male', 'MALE'],
  ['Female', 'female', 'FEMALE'],
  ['Other', 'other', 'OTHER'],
  ['Unknown', 'unknown', 'UNKNOWN'],
  ['Declined to Specify', 'declined', 'DECLINED'],
].map(([label, value, enumValue]) => ({
  label,
  value,
  enumValue,
}))

export const LEAD_STATUSES = [
  ['Pending', 'pending', 'PENDING'],
  ['Duplicate', 'duplicate', 'DUPLICATE'],
  ['Closed Won', 'won', 'WON'],
  ['Closed Lost', 'lost', 'LOST'],
].map(([label, value, enumValue]) => ({
  label,
  value,
  enumValue,
}))

export const LEAD_CUSTOMER_STATUSES = [
  ['Active', 'active', 'ACTIVE'],
  ['Churned', 'churned', 'CHURNED'],
  ['Trial', 'trial', 'TRIAL'],
].map(([label, value, enumValue]) => ({
  label,
  value,
  enumValue,
}))

export const passwordHelper = `
    Your password should contain at least one letter and one number, and the length should be 8
    characters or more!`

export const US_STATES = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['American Samoa', 'AS'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['District Of Columbia', 'DC'],
  ['Federated States Of Micronesia', 'FM'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Guam', 'GU'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Marshall Islands', 'MH'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Northern Mariana Islands', 'MP'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Palau', 'PW'],
  ['Pennsylvania', 'PA'],
  ['Puerto Rico', 'PR'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virgin Islands', 'VI'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY'],
].map(([label, value]) => ({ label, value }))

export const DOCTOR_SPECIALTIES = [
  ['NA', 'N/A', 'Not Applicable'],
  ['ACUPUNCTURE', 'Acupuncture', 'Acupuncture'],
  ['APM', 'Advanced Practice Midwife', 'Advanced Practice Midwife'],
  ['AM', 'Aesthetic Medicine', 'Aesthetic Medicine'],
  ['AESTHETICIAN', 'Aesthetician', 'Aesthetician'],
  ['AI', 'Allergist/Immunologist', 'Allergist/Immunologist'],
  ['ANESTHESIOLOGIST', 'Anesthesiologist', 'Anesthesiologist'],
  ['CE', 'Cardiac Electrophysiologist', 'Cardiac Electrophysiologist'],
  ['CARDIOLOGIST', 'Cardiologist', 'Cardiologist'],
  ['CAS', 'Cardiothoracic Surgeon', 'Cardiothoracic Surgeon'],
  ['CAP', 'Child/Adolescent Psychiatry', 'Child & Adolescent Psychiatry'],
  ['CHIROPRACTOR', 'Chiropractor', 'Chiropractor'],
  ['CSW', 'Clinical Social Worker', 'Clinical Social Worker'],
  ['COS', 'Colorectal Surgeon', 'Colorectal Surgeon'],
  ['CORREACTOLOGY', 'Correactology', 'Correactology'],
  ['CM', 'Cosmetic Medicine', 'Cosmetic Medicine'],
  ['CMH', 'Counselor Mental Health', 'Counselor Mental Health'],
  ['CP', 'Counselor Professional', 'Counselor Professional'],
  ['COUNSELOR', 'Counselor', 'Counselor'],
  ['DENTIST', 'Dentist', 'Dentist'],
  ['DIABETOLOGY', 'Diabetology', 'Diabetology'],
  ['DEERMATOLOGIST', 'Dermatologist', 'Dermatologist'],
  ['DMS', 'Diagnostic Medical Sonographer', 'Diagnostic Medical Sonographer'],
  ['DR', 'Dietitian, Registered', 'Dietitian, Registered'],
  ['ENT', 'Ear-Nose-Throat Specialist', 'Ear-Nose-Throat Specialist , ENT)'],
  ['EMP', 'Emergency Medicine Physician', 'Emergency Medicine Physician'],
  ['ENDOCRINOLOGIST', 'Endocrinologist', 'Endocrinologist'],
  ['ENDODONTIST', 'Endodontist', 'Endodontist'],
  ['EPIDEMIOLOGIST', 'Epidemiologist', 'Epidemiologist'],
  ['EP', 'Family Practitioner', 'Family Practitioner'],
  ['GASTROENTEROLOGIST', 'Gastroenterologist', 'Gastroenterologist'],
  ['GP', 'General Practice', 'General Practice'],
  ['GS', 'General Surgeon', 'General Surgeon'],
  ['GENETICIST', 'Geneticist', 'Geneticist'],
  ['GERIATRICIAN', 'Geriatrician', 'Geriatrician'],
  ['GERONTOLOGIST', 'Gerontologist', 'Gerontologist'],
  ['GYNECOLOGIST', 'Gynecologist , no OB)', 'Gynecologist , no OB)'],
  ['GO', 'Gynegologic Oncologist', 'Gynegologic Oncologist'],
  ['HS', 'Hand Surgeon', 'Hand Surgeon'],
  ['HEMATOLOGIST', 'Hematologist', 'Hematologist'],
  ['HC', 'Home Care', 'Home Care'],
  ['HOSPCIE', 'Hospice', 'Hospice'],
  ['HOSPITALIST', 'Hospitalist', 'Hospitalist'],
  ['IDS', 'Infectious Disease Specialist', 'Infectious Disease Specialist'],
  ['IFM', 'Integrative and Functional Medicine', 'Integrative and Functional Medicine'],
  ['IM', 'Integrative Medicine', 'Integrative Medicine'],
  ['INTERNIST', 'Internist', 'Internist'],
  ['IR', 'Interventional Radiology', 'Interventional Radiology'],
  ['LMS', 'Laboratory Medicine Specialist', 'Laboratory Medicine Specialist'],
  ['LS', 'Laser Surgery', 'Laser Surgery'],
  ['MT', 'Massage Therapist', 'Massage Therapist'],
  ['NATUROPATHICP', 'Naturopathic Physician', 'Naturopathic Physician'],
  ['NEONATOLOGIST', 'Neonatologist', 'Neonatologist'],
  ['NEPHROLOGIST', 'Nephrologist', 'Nephrologist'],
  ['NEUROLOGIST', 'Neurologist', 'Neurologist'],
  ['NEUROPSYCHOLOGY', 'Neuropsychology', 'Neuropsychology'],
  ['NEUROSURGEON', 'Neurosurgeon', 'Neurosurgeon'],
  ['NAP', 'Not Actively Practicing', 'Not Actively Practicing'],
  ['NMS', 'Nuclear Medicine Specialist', 'Nuclear Medicine Specialist'],
  ['NP', 'Nurse Practitioner', 'Nurse Practitioner'],
  ['NURSING', 'Nursing', 'Nursing'],
  ['NUTRITIONIST', 'Nutritionist', 'Nutritionist'],
  ['OG', 'Obstetrician/Gynecologist', 'Obstetrician/Gynecologist'],
  ['OM', 'Occupational Medicine', 'Occupational Medicine'],
  ['OT', 'Occupational Therapist', 'Occupational Therapist'],
  ['ONCOLOGIST', 'Oncologist', 'Oncologist'],
  ['OPHTALMOLOGIST', 'Ophthalmologist', 'Ophthalmologist'],
  ['OPTOMETRIST', 'Optometrist', 'Optometrist'],
  ['ORALS', 'Oral Surgeon', 'Oral Surgeon'],
  ['OP', 'Orofacial Pain', 'Orofacial Pain'],
  ['ORTHODONTIST', 'Orthodontist', 'Orthodontist'],
  ['OS', 'Orthopedic Surgeon', 'Orthopedic Surgeon'],
  ['ORTHOTIST', 'Orthotist', 'Orthotist'],
  ['OTHER', 'Other', 'Other'],
  ['PMS', 'Pain Management Specialist', 'Pain Management Specialist'],
  ['PATHLOGIST', 'Pathologist', 'Pathologist'],
  ['PD', 'Pediatric Dentist', 'Pediatric Dentist'],
  ['PG', 'Pediatric Gastroenterology', 'Pediatric Gastroenterology'],
  ['PEDIATRICS', 'Pediatric Surgeon', 'Pediatric Surgeon'],
  ['PEDIATRICIAN', 'Pediatrician', 'Pediatrician'],
  ['PEIANTOLOGIST', 'Perinatologist', 'Perinatologist'],
  ['PERIODONTIST', 'Periodontist', 'Periodontist'],
  ['PMRS', 'Physical Medicine and Rehab Specialist', 'Physical Medicine and Rehab Specialist'],
  ['PT', 'Physical Therapist', 'Physical Therapist'],
  ['PA', 'Physician Assistant', 'Physician Assistant'],
  ['PLASTICS', 'Plastic Surgeon', 'Plastic Surgeon'],
  ['PODIATRIST', 'Podiatrist', 'Podiatrist'],
  ['PAM', 'Preventive-Aging Medicine', 'Preventive-Aging Medicine'],
  [
    'PMOE',
    'Preventive Medicine/Occupational-Environmental Medicine',
    'Preventive Medicine/Occupational-Environmental Medicine',
  ],
  ['PCP', 'Primary Care Physician', 'Primary Care Physician'],
  ['PROSTHETIST', 'Prosthetist', 'Prosthetist'],
  ['PROSTHODONTIST', 'Prosthodontist', 'Prosthodontist'],
  ['PSYCHIATRIST', 'Psychiatrist', 'Psychiatrist'],
  ['PSYCHOLOGIST', 'Psychologist', 'Psychologist'],
  ['PHP', 'Public Health Professional', 'Public Health Professional'],
  ['PULMONOLOGIST', 'Pulmonologist', 'Pulmonologist'],
  ['RO', 'Radiation Oncologist', 'Radiation Oncologist'],
  ['RADILOGIST', 'Radiologist', 'Radiologist'],
  ['RN', 'Registered Nurse', 'Registered Nurse'],
  ['RNP', 'Religious Nonmedical Practitioner', 'Religious Nonmedical Practitioner'],
  ['RE', 'Reproductive Endocrinologist', 'Reproductive Endocrinologist'],
  ['RM', 'Reproductive Medicine', 'Reproductive Medicine'],
  ['RHEUMATOLOGIST', 'Rheumatologist', 'Rheumatologist'],
  ['SM', 'Sleep Medicine', 'Sleep Medicine'],
  ['SLP', 'Speech-Language Pathologist', 'Speech-Language Pathologist'],
  ['SMS', 'Sports Medicine Specialist', 'Sports Medicine Specialist'],
  ['UROLOGIST', 'Urologist', 'Urologist'],
  ['UC', 'Urgent Care', 'Urgent Care'],
  ['VS', 'Vascular Surgeon', 'Vascular Surgeon'],
].map(([enumValue, value, label]) => ({
  label,
  value,
  enumValue,
}))

export const SPECIALTIES = [
  'Acupuncture',
  'Addiction Medicine',
  'Aesthetics / Medical Spa',
  'Allergy & Immunology',
  'Alternative & Holistic Medicine',
  'Ambulatory Clinics',
  'Anesthesiology',
  'Audiology',
  'Bariatric Medicine',
  'Behavioral Health',
  'Billing Services',
  'Cardiology',
  'Chiropractic',
  'Colon and Rectal Surgery',
  'Dentistry',
  'Dermatology',
  'Dialysis Clinic',
  'Dietitian / Nutrition',
  'Emergency Medicine',
  'Endocrinology',
  'Family Medicine',
  'Gastroenterology',
  'General Practice ',
  'General Surgery',
  'Geriatric',
  'Hormone Therapy',
  'Hematology',
  'Holistic Medicine',
  'Home Care',
  'Hospice',
  'Hospitalist',
  'Infectious Diseases',
  'Internal Medicine',
  'IV Therapy ',
  'Medical Genetics',
  'Mental & Behavioral Health',
  'Midwife',
  'Multi-Specialty',
  'Naturopathic',
  'Nephrology',
  'Neurologic Surgery',
  'Neurology',
  'Neurosurgery',
  'Nurse Practitioner',
  'Obstetrics & Gynecology',
  'Occupational Medicine',
  'Oncology',
  'Ophthalmology',
  'Optometry',
  'Oral/Dental Surgery',
  'Orthopedic Surgery',
  'Orthopedics',
  'Otolaryngology (ENT)',
  'Pain Management ',
  'Pathology-Anatomic & Clinical ',
  'Pediatric Cardiology',
  'Pediatric Emergency Medicine',
  'Pediatric Endocrinology',
  'Pediatric Gastroenterology',
  'Pediatric Hematology-Oncology',
  'Pediatric Infectious Diseases',
  'Pediatric Nephrology',
  'Pediatric Otolaryngology',
  'Pediatric Pulmonology',
  'Pediatric Rheumatology',
  'Pediatric Sports Medicine',
  'Pediatric Surgery',
  'Pediatric Urology',
  'Pediatrics',
  'Peditaric Pathology',
  'Peditaric Radiology',
  'Perinatologist',
  'Periodontist',
  'Physical Medicine & Rehabilitation',
  'Physical Therapy',
  'Plastic Surgery',
  'Podiatry',
  'Preventive Medicine',
  'Primary Care',
  'Psychiatry',
  'Psychology',
  'Pulmonology',
  'Radiation Oncology',
  'Radiology-Diagnostic',
  'Regenerative Medicine',
  'Rheumatology',
  'Sleep Medicine',
  'Speech-Language Pathology',
  'Sports Medicine',
  'Surgery',
  'Thoracic Surgery',
  'Urgent Care',
  'Urology',
  'Vascular Surgery',
  'Weight Management',
  'Wound Care',
]

/**
 * This represents the maximum resolution (width in pixels) for a specific screen to be considered: mobile, tablet or desktop.
 * That being said, it is considered to be mobile resolution screen a screen with a maximum of 767px width.
 * Following the same logic a tablet resolution screen has the width between 768px and 991px, resulting desktop to have a starting point of 992px.
 */
export const RESOLUTION_BREAKPOINTS = {
  MOBILE: 767,
  TABLET: 991,
}

export const COVER_COLORS = {
  LIGHT_GRAY: '#D9E3F0',
  RED: '#F47373',
  DARK_GRAY: '#697689',
  GREEN: '#37D67A',
  BLUE: '#2CCCE4',
  BLACK: '#555555',
  YELLOW: '#DCE775',
  ORANGE: '#FF8A65',
  PURPLE: '#BA68C8',
}
