import React from 'react'
import { useLocation } from 'react-router-dom'
import { Alert } from 'reactstrap'
import { loader as gqlLoader } from 'graphql.macro'
import { useQuery } from '@apollo/client'

import Loader from 'components/Common/Loader'
import Footer from 'components/Base/Footer'
import Header from 'components/Base/Header'
import { DevSidebar, DevSidebarMobile } from 'components/Dev/DevSidebar'
import Breadcrumb from 'components/Common/Breadcrumb'
import { URLS } from 'config/urls'
import s from './DevLayout.module.css'

const ANNOUNCEMENTS = gqlLoader('graphql/queries/Announcements.graphql')

const DevLayout = ({ children, includeSidebar = true }) => {
  const location = useLocation()
  const { loading, data } = useQuery(ANNOUNCEMENTS)
  return (
    <>
      <Header showNavigation={false} />
      <div className="page-container">
        {includeSidebar && (
          <DevSidebar activeRoute={routeName => location.pathname === routeName} />
        )}
        <div className="page-content-dev">
          <Breadcrumb
            routePath={URLS.APPS.HOMEPAGE}
            routeLabel="Browse Marketplace"
            backLink={true}
          />
          <div className={s.layout}>
            {loading ? (
              <Loader />
            ) : (
              <div className={s.announcements}>
                {data.announcements.map(({ text }, index) => (
                  <Alert key={index} color="info">
                    {text}
                  </Alert>
                ))}
              </div>
            )}
            {includeSidebar && (
              <DevSidebarMobile activeRoute={routeName => location.pathname === routeName} />
            )}
            <div className={s.layoutContent}>{children}</div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default DevLayout
