import React from 'react'

import s from './Footer.module.css'

const Footer = () => {
  return (
    <footer className={s.footerSection}>
      <div className={s.copyright}>© 2021 DrChrono Inc.</div>
      <div className={s.menu}>
        <ul>
          <li>
            <a href="https://www.drchrono.com/ehr-emr/privacy-policy/">Privacy Policy</a>
          </li>
          <li>
            <a href="https://www.drchrono.com/ehr-emr/terms/">Terms of Use</a>
          </li>
          <li>
            <a href="https://support.drchrono.com/hc/en-us">Support Center</a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
