import React, { useState } from 'react'
import cn from 'classnames'

import burgerMenuCloseIcon from 'assets/images/burger-menu-close-icon.svg'

import DevSidebarContent from "./DevSidebarContent"
import s from './DevSidebarMobile.module.css'

const DevSidebarMobile = ({ activeRoute }) => {
	const [isExpanded, setIsExpanded] = useState(false)

	return (
		<div className={cn(s.sidebar, { [s.isExpanded]: isExpanded })}>
			<button className={s.sidebarToggleOpen} onClick={() => setIsExpanded(true)}>
				View all settings tabs
				<i className="fa fa-angle-right" />
			</button>

			<div className={s.sidebarContentWrapper}>
				<button className={s.sidebarToggleClose} onClick={() => setIsExpanded(false)}>
					Hide the settings tabs
					<img
						className={s.dashboardMenuToggleClose}
						src={burgerMenuCloseIcon}
						alt="mobile menu close icon"
					/>
				</button>
				<DevSidebarContent activeRoute={activeRoute} onClick={() => setIsExpanded(false)} />
			</div>
		</div>
	)
}

export default DevSidebarMobile
