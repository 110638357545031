import React, { Suspense, lazy } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { hot } from 'react-hot-loader/root'

import AuthRoute from 'components/AuthRoute'
import DevLayout from 'components/Dev/DevLayout'
import OauthLoginSuccess from 'components/Accounts/OauthLoginSuccess'
import Layout from 'components/Base/Layout'
import Loader from 'components/Common/Loader'
import UserProfile from 'components/Accounts/UserProfile'
import NetworkHomepage from 'components/Network/NetworkHomepage'
import Doctor from 'components/Network/Doctor'
import LoginContainer from 'containers/Accounts/LoginContainer'
import LogoutContainer from 'containers/Accounts/LogoutContainer'
import { ScreenResolutionContextProvider } from 'utils/ScreenResolutionContext'
import { IS_DEV } from 'config/config'
import { URLS } from 'config/urls'
import { componentLoader } from 'utils'

import 'components/Base/root.module.css'

const ClientRoute = ({ component, ...rest }) => (
  <AuthRoute layout={Layout} component={component} {...rest} />
)

const ClientAuthRoute = ({ component, ...rest }) => (
  <AuthRoute layout={Layout} component={component} authRequired {...rest} />
)

const DevRoute = ({ component, ...rest }) => (
  <AuthRoute {...rest} layout={DevLayout} component={component} />
)

const Homepage = lazy(() => componentLoader(() => import('components/Product/Homepage'), 3))
const ProductContainer = lazy(() =>
  componentLoader(() => import('components/Product/ProductContainer'), 3),
)
const ProductsByCategory = lazy(() =>
  componentLoader(() => import('components/Product/ProductsByCategory'), 3),
)
const Resources = lazy(() => componentLoader(() => import('components/Resources/Resources'), 3))
const Resource = lazy(() => componentLoader(() => import('components/Resources/Resource'), 3))
const RequestedProducts = lazy(() =>
  componentLoader(() => import('components/RequestedProducts/RequestedProducts'), 3),
)
const RequestedProduct = lazy(() =>
  componentLoader(() => import('components/RequestedProducts/RequestedProduct'), 3),
)
const BecomePartner = lazy(() =>
  componentLoader(() => import('components/BecomePartner/BecomePartner'), 3),
)
const DevLeads = lazy(() => componentLoader(() => import('components/Dev/DevLeads'), 3))
const DevLeadsReporting = lazy(() =>
  componentLoader(() => import('components/Dev/DevLeadsReporting'), 3),
)
const DevProductContainer = lazy(() =>
  componentLoader(() => import('components/Dev/DevProductContainer')),
)
const DevProducts = lazy(() => componentLoader(() => import('components/Dev/DevProducts'), 3))
const DevTeam = lazy(() => componentLoader(() => import('components/Dev/DevTeam'), 3))
const DevHelp = lazy(() => componentLoader(() => import('components/Dev/DevHelp'), 3))
const DevViews = lazy(() => componentLoader(() => import('components/Dev/DevViews'), 3))

const AppRoot = () => {
  return (
    <ScreenResolutionContextProvider>
      <Suspense fallback={<Loader />}>
        <Switch>
          {/* PRODUCT */}
          <ClientRoute exact path={URLS.APPS.HOMEPAGE} component={Homepage} />
          <ClientRoute exact path={URLS.SERVICES.HOMEPAGE} component={Homepage} />
          <ClientRoute exact path={URLS.APPS.DETAILS} component={ProductContainer} />
          <ClientRoute exact path={URLS.SERVICES.DETAILS} component={ProductContainer} />

          {/* CATEGORIES */}
          <ClientRoute exact path={URLS.APPS.CATEGORY} component={ProductsByCategory} />
          <ClientRoute exact path={URLS.SERVICES.CATEGORY} component={ProductsByCategory} />

          {/* RESOURCES */}
          <ClientRoute exact path={URLS.RESOURCES.HOMEPAGE} component={Resources} />
          <ClientRoute exact path={URLS.RESOURCES.DETAILS} component={Resource} />

          {/* NETWORK */}
          <ClientRoute exact path={URLS.DOCTORS.HOMEPAGE} component={NetworkHomepage} />
          <ClientRoute exact path={URLS.DOCTORS.DOCTOR} component={Doctor} />

          {/* REQUESTED PRODUCTS */}
          <ClientRoute
            exact
            path={URLS.REQUESTED_PRODUCTS.HOMEPAGE}
            component={RequestedProducts}
          />
          <ClientRoute exact path={URLS.REQUESTED_PRODUCTS.DETAILS} component={RequestedProduct} />
          {/* BECOME A PARTNER */}
          <ClientRoute exact path={URLS.BECOME_PARTNER.HOMEPAGE} component={BecomePartner} />

          {/* Only backend sends to this route when oauth fails */}
          <ClientRoute
            exact
            path={URLS.AUTH.OAUTH_LOGIN_DENIED}
            layoutProps={{ oauthDenied: true }}
            component={Homepage}
          />
          <ClientRoute exact path={URLS.AUTH.OAUTH_LOGIN_SUCCESS} component={OauthLoginSuccess} />
          {IS_DEV ? <ClientRoute exact path={URLS.AUTH.LOGIN} component={LoginContainer} /> : null}
          <DevRoute exact path={URLS.DEV.APPS} component={DevProducts} />
          <DevRoute exact path={URLS.DEV.SERVICES} component={DevProducts} />
          <DevRoute exact path={URLS.DEV.VIEWS} component={DevViews} />
          <DevRoute exact path={URLS.DEV.LEADS} component={DevLeads} />
          <DevRoute
            exact
            path={URLS.DEV.LEADS_REPORTING}
            component={DevLeadsReporting}
            componentProps={{
              isCustomerReport: false,
            }}
          />
          <DevRoute
            exact
            path={URLS.DEV.CUSTOMER_REPORTING}
            component={DevLeadsReporting}
            componentProps={{
              isCustomerReport: true,
            }}
          />
          <DevRoute exact path={URLS.DEV.REFERRALS} component={DevLeads} />
          <DevRoute exact path={URLS.DEV.MY_TEAM} component={DevTeam} />
          <DevRoute exact path={URLS.DEV.HELP} component={DevHelp} />
          <DevRoute
            exact
            path={URLS.DEV.APP}
            component={DevProductContainer}
            layoutProps={{ includeSidebar: false }}
          />
          <DevRoute
            exact
            path={URLS.DEV.SERVICE}
            component={DevProductContainer}
            layoutProps={{ includeSidebar: false }}
          />
          <ClientAuthRoute exact path={URLS.MY_PROFILE} component={UserProfile} />
          <ClientAuthRoute exact path={URLS.AUTH.LOGOUT} component={LogoutContainer} />
          <Redirect to={URLS.APPS.HOMEPAGE} />
        </Switch>
      </Suspense>
    </ScreenResolutionContextProvider>
  )
}

export default hot(AppRoot)
