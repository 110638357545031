import React from 'react'
import { FormFeedback, Input } from 'reactstrap'

const FormikField = ({
  field,
  values,
  errors,
  touched,
  handleChange,
  placeholder = '',
  name = null,
  type = 'text',
  id = null,
}) => {
  return (
    <>
      <Input
        type={type}
        valid={Boolean(values[field] && !errors[field])}
        invalid={Boolean(errors[field] && touched[field])}
        name={name || field}
        rows={5}
        id={id || field}
        placeholder={placeholder}
        value={values[field] === null ? '' : values[field]}
        onChange={handleChange}
      />
      {errors[field] && touched[field] && <FormFeedback>{errors[field]}</FormFeedback>}
    </>
  )
}

export default FormikField
