import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import cn from 'classnames'
import { Button } from 'reactstrap'
import { useLocation, Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { loader as gqlLoader } from 'graphql.macro'
import { useQuery } from '@apollo/client'
import ClampLines from 'react-clamp-lines'

import genericProduct from 'assets/images/generic-product.png'
import genericAvatar from 'assets/images/generic-avatar.png'
import Breadcrumb from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import { CardsList, Card } from 'components/Common/Card'
import MustLoginDialog from 'components/Common/MustLoginDialog'
import withErrorHandler from 'HOC/withErrorHandler'
import { userNetworkPath, requestedProductPath, productPath, URLS } from 'config/urls'
import { formatDate, pluralize } from 'utils'
import { GENDERS, TITLES, US_STATES, COVER_COLORS, DOCTOR_SPECIALTIES } from 'utils/constants'

import s from './Doctor.module.css'

const DOCTOR = gqlLoader('graphql/queries/Doctor.graphql')
const FOLLOW_USER = gqlLoader('graphql/mutations/FollowUser.graphql')

const TABS = {
  ACTIVITY: 'ACTIVITY',
  FOLLOWING: 'FOLLOWING',
  FOLLOWERS: 'FOLLOWER',
}

const ActivityTab = ({ doctor }) => {
  if (!doctor.leads.length) {
    return null
  }

  return (
    <>
      {Boolean(doctor.productsDeveloped) && (
        <>
          <div className={s.cardsListTitle}>Products Created</div>
          <CardsList>
            {doctor.productsDeveloped.map(product => {
              return (
                <Card linkTo={productPath(product)} key={product.uuid} className={s.cardsListItem}>
                  <img
                    className={s.cardsListItemImage}
                    alt={product.name}
                    src={product.logoThumbnail || genericProduct}
                  />
                  <div className={s.cardsListItemInfo}>
                    <div className={s.cardsListItemInfoName}>{product.name}</div>
                    <ClampLines
                      className={s.cardsListItemInfoDescription}
                      text={product.summary}
                      lines={2}
                      ellipsis="..."
                      innerElement="div"
                      buttons={false}
                    />
                  </div>
                </Card>
              )
            })}
          </CardsList>
        </>
      )}
      {Boolean(doctor.leads.length) && (
        <>
          <div className={s.cardsListTitle}>Interested Apps & Services</div>
          <CardsList>
            {doctor.leads.map(({ app, service }) => {
              const product = app || service
              return (
                <Card linkTo={productPath(product)} key={product.uuid} className={s.cardsListItem}>
                  <img
                    className={s.cardsListItemImage}
                    alt={product.name}
                    src={product.logoThumbnail || genericProduct}
                  />
                  <div className={s.cardsListItemInfo}>
                    <div className={s.cardsListItemInfoName}>{product.name}</div>
                    <ClampLines
                      className={s.cardsListItemInfoDescription}
                      text={product.summary}
                      lines={2}
                      ellipsis="..."
                      innerElement="div"
                      buttons={false}
                    />
                  </div>
                </Card>
              )
            })}
          </CardsList>
        </>
      )}
      {Boolean(doctor.reviews.length) && (
        <>
          <div className={s.cardsListTitle}>Reviews</div>
          <CardsList>
            {doctor.reviews.map(review => {
              const product = review.app || review.service
              return (
                <Card linkTo={productPath(product)} key={product.uuid} className={s.cardsListItem}>
                  <img
                    className={s.cardsListItemImage}
                    alt={product.name}
                    src={product.logoThumbnail || genericProduct}
                  />
                  <div className={s.cardsListItemInfo}>
                    <div className={s.cardsListItemInfoName}>{product.name}</div>
                    <ClampLines
                      className={s.cardsListItemInfoDescription}
                      text={product.summary}
                      lines={2}
                      ellipsis="..."
                      innerElement="div"
                      buttons={false}
                    />
                  </div>
                </Card>
              )
            })}
          </CardsList>
        </>
      )}
    </>
  )
}

const FollowTab = ({ users, label }) => {
  return (
    <>
      <div className={s.cardsListTitle}>
        {label} ({users.length})
      </div>
      <CardsList>
        {users.map(user => (
          <Card linkTo={userNetworkPath(user)} key={user.uuid} className={s.cardsListItem}>
            <div
              className={s.cardsListItemImage}
              alt={user.name}
              style={{
                backgroundImage: `url("${user.profile.avatar || genericAvatar}")`,
              }}
            />
            <div className={s.cardsListItemInfo}>
              <div className={s.cardsListItemInfoName}>{user.name}</div>
              {user.profile.specialty && (
                <div className={s.cardsListItemInfoName}>
                  {
                    DOCTOR_SPECIALTIES.find(({ enumValue }) => enumValue === user.profile.specialty)
                      .label
                  }
                </div>
              )}
            </div>
          </Card>
        ))}
      </CardsList>
    </>
  )
}

const Doctor = ({ currentUser, match }) => {
  const [showMustLogin, setShowMustLogin] = useState(false)
  const [activeTab, setActiveTab] = useState(TABS.ACTIVITY)
  const [followUser] = useMutation(FOLLOW_USER)

  const location = useLocation()
  const { loading, data } = useQuery(DOCTOR, {
    variables: {
      uuid: match.params.uuid,
    },
  })

  const doctor = data && data.doctor
  const profile = doctor && doctor.profile

  const handleFollowUser = async () => {
    await followUser({
      variables: {
        userUuid: doctor.uuid,
      },
    })
  }

  const currentUserIsFollowing =
    currentUser && !loading && doctor.followers.find(({ uuid }) => uuid === currentUser.uuid)

  return (
    <div className="page-container">
      {loading ? (
        <Loader />
      ) : data ? (
        <div className="page-content">
          <Helmet>
            <title>
              DrChrono - {doctor.firstName} {doctor.lastName}
            </title>
            <meta name="description" content={`DrChrono is partnered with ${doctor.name}`} />
            <link rel="canonical" href={`https://partners.drchrono.com${location.pathname}`} />
          </Helmet>
          <Breadcrumb
            routePath={URLS.DOCTORS.HOMEPAGE}
            routeLabel="Browse Network"
            backLink={true}
          />
          <Card className={s.header}>
            <div
              className={s.headerProfile}
              style={{
                backgroundColor: COVER_COLORS[profile.coverColor],
              }}
            >
              <div
                className={s.headerProfileAvatar}
                style={{
                  backgroundImage: `url("${profile.avatar || genericAvatar}")`,
                }}
              ></div>
              <div className={s.headerProfileInfo}>
                <div className={cn(s.headerProfileInfoItem, s.headerProfileInfoName)}>
                  {`${doctor.firstName} ${doctor.lastName}`}
                </div>
                {profile.title && (
                  <div>{TITLES.find(({ enumValue }) => enumValue === profile.title).label}</div>
                )}
                {profile.gender && (
                  <div>{GENDERS.find(({ enumValue }) => enumValue === profile.gender).label}</div>
                )}
                {profile.specialty && (
                  <div>
                    {
                      DOCTOR_SPECIALTIES.find(({ enumValue }) => enumValue === profile.specialty)
                        .label
                    }
                  </div>
                )}
                {profile.state && (
                  <div className={s.headerProfileInfoItem}>
                    {US_STATES.find(state => state.value === profile.state).label}
                    {profile.city && ` / ${profile.city}`}
                  </div>
                )}
                <Button
                  size="sm"
                  className={cn(s.headerProfileInfoButton, {
                    [s.headerProfileInfoButtonFollowing]: currentUser && currentUserIsFollowing,
                  })}
                  onClick={() => {
                    currentUser ? handleFollowUser() : setShowMustLogin(true)
                  }}
                >
                  {currentUserIsFollowing ? 'Following' : 'Follow'}
                </Button>
                {showMustLogin && (
                  <MustLoginDialog
                    onClose={() => setShowMustLogin(false)}
                    message="Login to follow"
                  />
                )}
              </div>
            </div>
            <div
              className={s.headerTabNavigation}
              onClick={e => {
                if (e.target.dataset?.tab) {
                  setActiveTab(e.target.dataset.tab)
                }
              }}
            >
              {Object.values(TABS).map(tab => (
                <div
                  className={cn(s.headerTabNavigationItem, {
                    [s.headerTabNavigationItemActive]: activeTab === tab,
                  })}
                  data-tab={tab}
                  key={tab}
                >
                  {tab === TABS.FOLLOWERS &&
                    `${doctor.followers.length} ${pluralize(tab, doctor.followers.length, false)}`}
                  {tab === TABS.FOLLOWING && `${doctor.following.length} ${tab}`}
                  {![TABS.FOLLOWING, TABS.FOLLOWERS].includes(tab) && tab}
                </div>
              ))}
            </div>
          </Card>
          <div className={s.content}>
            <div className={s.contentLeftSide}>
              <div className={s.contentTabView}>
                {activeTab === TABS.ACTIVITY && <ActivityTab doctor={doctor} />}
                {activeTab === TABS.FOLLOWING && (
                  <FollowTab users={doctor.following} label="Following" />
                )}
                {activeTab === TABS.FOLLOWERS && (
                  <FollowTab users={doctor.followers} label="Followers" />
                )}
              </div>
            </div>
            <div className={s.contentRightSide}>
              <Card className={s.additionalInfo}>
                <div>
                  <div className={cn(s.additionalInfoJoinedDate, s.additionalInfoItem)}>
                    <i className="fa fa-birthday-cake" />
                    Joined on {formatDate(new Date(doctor.created))}
                  </div>
                  {profile.cellPhone && (
                    <div className={s.additionalInfoItem}>Cell Phone: {profile.cellPhone}</div>
                  )}
                  {profile.officePhone && (
                    <div className={s.additionalInfoItem}>Office Phone: {profile.officePhone}</div>
                  )}
                  {profile.homePhone && (
                    <div className={s.additionalInfoItem}>Home Phone: {profile.homePhone}</div>
                  )}
                  {profile.linkedin && (
                    <div className={s.additionalInfoItem}>
                      <a
                        href={profile.linkedin}
                        className={s.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-linkedin" />
                        Linkedin
                      </a>
                    </div>
                  )}
                  {profile.website && (
                    <div className={s.additionalInfoItem}>
                      <a href={profile.website} target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-globe" />
                        Website
                      </a>
                    </div>
                  )}
                </div>
              </Card>
              {!!doctor.upvotedRequestedProducts.length && (
                <>
                  <div className={s.cardsListTitle}>Upvoted Products</div>
                  <CardsList>
                    {doctor.upvotedRequestedProducts.map(p => (
                      <Card
                        linkTo={requestedProductPath(p)}
                        className={s.cardsListItem}
                        key={p.uuid}
                      >
                        <img
                          className={s.cardsListItemImage}
                          alt="upvoted product"
                          src={p.logoThumbnail || genericProduct}
                        />
                        <div className={s.cardsListItemInfo}>
                          <div className={s.cardsListItemInfoName}>{p.name}</div>
                          <ClampLines
                            className={s.cardsListItemInfoDescription}
                            text={p.description}
                            lines={2}
                            ellipsis="..."
                            innerElement="div"
                            buttons={false}
                          />
                        </div>
                      </Card>
                    ))}
                  </CardsList>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Redirect to={URLS.APPS.HOMEPAGE} />
      )}
    </div>
  )
}

export default withErrorHandler(Doctor)
