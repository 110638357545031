import React, { createContext } from 'react'

import useWindowSize from 'utils/useWindowSize'
import { RESOLUTION_BREAKPOINTS } from 'utils/constants'

/* Create the context */
const ScreenResolutionContext = createContext({
  mobile: false,
  tablet: false,
  desktop: false,
})

/* Contstruct the context provider */
const ScreenResolutionContextProvider = ({ children }) => {
  /* Use the actual resolution values */
  const { width } = useWindowSize()

  /* Compute the resolution values into device specific tags */
  return (
    <ScreenResolutionContext.Provider
      value={{
        mobile: width <= RESOLUTION_BREAKPOINTS.MOBILE,
        tablet: width > RESOLUTION_BREAKPOINTS.MOBILE && width <= RESOLUTION_BREAKPOINTS.TABLET,
        desktop: width > RESOLUTION_BREAKPOINTS.TABLET,
      }}
    >
      {children}
    </ScreenResolutionContext.Provider>
  )
}

export { ScreenResolutionContextProvider }
export default ScreenResolutionContext
