import { PRODUCT_TYPE } from './constants'

// https://medium.com/@botfather/react-loading-chunk-failed-error-88d0bb75b406
export const componentLoader = (lazyComponent, attemptsLeft) =>
  new Promise((resolve, reject) =>
    lazyComponent()
      .then(resolve)
      .catch(error => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error)
            return
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject)
        }, 1500)
      }),
  )

export const truncate = (s, len = 80) => {
  if (!s) return ''
  if (s.length > len) return `${s.substring(0, len)}...`
  return s
}

export const getLabelFromProductType = productType => {
  return productType === PRODUCT_TYPE.APP ? 'App' : 'Service'
}

export const pluralize = (word, count, smallCase = true) =>
  count === 1 ? word : `${word}${smallCase ? 's' : 'S'}`

export const getStatusBadgeTitle = (status, productType) => {
  const label = getLabelFromProductType(productType)
  return {
    SINGLE: `Single mode development. Send your ${label} to review after you have completed all the details.`,
    REVIEW: 'Under DrChrono team review.',
    REJECTED: `${label} was rejected because you need to make updates to it. Contact DrChrono for more information.`,
    NOTARIZED: `No legal documents need to be signed. We notarized your ${label}, but it cannot go live.`,
    READY: `All legal documents were signed and you can now push the ${label} to be live.`,
    LIVE: 'Live',
    DEV_REJECTED: `Removed from the ${label} store. Cannot make any edits.`,
  }[status]
}

export const getStatusText = (status, productType) => {
  const label = getLabelFromProductType(productType)
  return {
    SINGLE: `After you're done editting your ${label}, you can lock it and send it to be reviewed by the DrChrono team.`,
    REVIEW: `The ${label} is under DrChrono team review. If you wish to withdraw and update it again, click the button below.`,
    READY: `Your ${label} has been approved and all the documents were signed. You can now publish your ${label}.`,
    LIVE: `Your ${label} is now LIVE. If you wish to temporarily remove it from the DrChrono Marketplace, use the button below.`,
    NOT_DEV_REJECTED: `If your ${label} is listed in the Marketplace, please communicate any plans to deprecate it to your users, and be prepared to respond to questions and support requests after deleting it.`,
  }[status]
}

export const formatDate = date => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  return `${monthNames[monthIndex]} ${day} ${year}`
}

export const createEmptyArray = (val, length) => {
  const arr = []
  for (let i = 0; i < length; i += 1) {
    arr.push(val)
  }
  return arr
}

export const getDaysDifference = (d1, d2) => {
  const diffTime = Math.abs(d2 - d1)
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}
