import React, { useState } from 'react'
import cn from 'classnames'

import LoadingGif from 'components/Common/LoadingGif'

import s from './ImageUpload.module.css'

const ImageUpload = ({ onChange: onChangeCallback, name, id, className }) => {
  const [imageLoading, setImageLoading] = useState(false)

  const onChange = e => {
    setImageLoading(true)

    const reader = new FileReader()
    const file = e.target.files[0]

    reader.onloadend = () => {
      const image = new Image()
      image.src = reader.result

      image.onload = () => {
        onChangeCallback(image)
        setImageLoading(false)
      }

      image.onerror = () => {
        setImageLoading(false)
      }
    }

    reader.onerror = () => {
      setImageLoading(false)
    }

    reader.readAsDataURL(file)
  }

  return (
    <div className={cn(s.imageUpload, className)}>
      <input
        accept="image/png,image/jpg,image/jpeg"
        type="file"
        id="image"
        name="image"
        className={s.imageUploadInput}
        onChange={onChange}
      />
      <label htmlFor="image" className={s.imageUploadLabel}>
        {imageLoading ? (
          <LoadingGif loading={true} />
        ) : (
          <>
            <i className="fa fa-upload" />
            <span>Choose image</span>
          </>
        )}
      </label>
    </div>
  )
}

export default ImageUpload
