import React from 'react'

import DevSidebarContent from './DevSidebarContent'
import s from './DevSidebar.module.css'

const DevSidebar = ({ activeRoute }) => {
  return (
    <div className={s.sidebar}>
      <DevSidebarContent activeRoute={activeRoute} />
    </div>
  )
}

export default DevSidebar
