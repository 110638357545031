const getBackendEndpoint = (suffix = '') => `${process.env.REACT_APP_BACKEND_HOST}${suffix}`

const GRAPHQL_ENDPOINT = getBackendEndpoint('/graphql/')
const TOKEN_KEY = 'appstore-auth-token'
const UTM_PARAMS_KEY = 'drcmarketplaceutmparams'
const LOGIN_REDIRECT_URL_KEY = 'drcloginredirecturl'

// URL they get redirected to after authorizing list of perimissions AppDirectory requested
const DRCHRONO_OAUTH_LOGIN_REDIRECT_URI = `${process.env.REACT_APP_DRCHRONO_OAUTH_LOGIN_REDIRECT_URI}`
const DRCHRONO_EHR_URL = `${process.env.REACT_APP_DRCHRONO_EHR_URL}`
const DRCHRONO_CLIENT_ID = process.env.REACT_APP_DRCHRONO_CLIENT_ID
const IS_DEV = process.env.REACT_APP_IS_DEV
const SENTRY_DSN = process.env.REACT_APP_RAVEN_DSN

// Scopes to request when authenticating with the "Login with DrChrono" button
// URL-encoded params (replacing "?" with "%3F" etc)
const DRCHRONO_SCOPES = 'user%3Aread'
// "appdirectory" from the URL below is the user in drchrono main CRM
const DRCHRONO_AUTHORIZE_URL = `https://${DRCHRONO_EHR_URL}/o/authorize/?redirect_uri=${DRCHRONO_OAUTH_LOGIN_REDIRECT_URI}&response_type=code&client_id=${DRCHRONO_CLIENT_ID}&scope=${DRCHRONO_SCOPES}`

export {
  IS_DEV,
  GRAPHQL_ENDPOINT,
  LOGIN_REDIRECT_URL_KEY,
  SENTRY_DSN,
  TOKEN_KEY,
  UTM_PARAMS_KEY,
  DRCHRONO_AUTHORIZE_URL,
  getBackendEndpoint,
}
