import { LOGIN_REDIRECT_URL_KEY, TOKEN_KEY, UTM_PARAMS_KEY } from 'config/config'

export const isSocialUser = () => localStorage.getItem('isSocialUser')
export const setIsSocialUser = () => localStorage.setItem('isSocialUser', true)
export const deleteIsSocialUser = () => localStorage.removeItem('isSocialUser')

export const getToken = () => localStorage.getItem(TOKEN_KEY)
export const isDrChronoAuthenticated = () => Boolean(getToken()) && !isSocialUser()
export const isSocialAuthenticated = () => Boolean(getToken()) && isSocialUser()
export const isAuthenticated = () => Boolean(getToken())

export const authenticate = token => localStorage.setItem(TOKEN_KEY, token)

export const getLoginRedirectUrl = () => localStorage.getItem(LOGIN_REDIRECT_URL_KEY)

export const setLoginRedirectUrl = loginredirectUrl =>
  localStorage.setItem(LOGIN_REDIRECT_URL_KEY, loginredirectUrl)

export const deleteLoginRedirectUrl = () => {
  localStorage.removeItem(LOGIN_REDIRECT_URL_KEY)
}

export const logout = client => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem('isSocialUser')
  client.resetStore()
}

export const clearUTMParams = () => localStorage.removeItem(UTM_PARAMS_KEY)

export const setUTMParams = params => localStorage.setItem(UTM_PARAMS_KEY, JSON.stringify(params))

export const getUTMParams = () => JSON.parse(localStorage.getItem(UTM_PARAMS_KEY))
