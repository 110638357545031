import React from "react"
import { NavLink } from "react-router-dom"
import cn from "classnames"

import s from "./Card.module.css"

const Card = ({ children, linkTo, className }) => {
	return linkTo ?
		<NavLink to={linkTo} className={cn(s.card, className)}>{children}</NavLink> :
		<div className={cn(s.card, className)}>{children}</div>
}

export default Card