import React from 'react'
import cn from 'classnames'

import s from 'containers/HeaderSearchContainer.module.css'
import Search from 'components/Common/Search'

const HeaderSearchContainer = () => {
  return (
    <div className={s.headerSearch}>
      <i className={cn('fa fa-search', s.headerSearchIcon)} />
      <div className={s.headerSearchContainer}>
        <Search />
      </div>
    </div>
  )
}

export default HeaderSearchContainer
