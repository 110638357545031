import React from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import {
  APP,
  APPS,
  BECOME_PARTNER,
  NETWORK,
  REQUESTED_PRODUCT,
  SERVICE,
  SERVICES,
  RESOURCE,
  RESOURCES,
  URLS,
} from 'config/urls'
import s from './MainNavigation.module.css'

/* This component will be used in the Homepage Sidebar too, on different resolutions */
const MainNavigation = () => {
  const { pathname } = useLocation()
  const navigation = [
    {
      name: 'Apps',
      isActive: pathname =>
        pathname === URLS.APPS.HOMEPAGE || pathname.startsWith(APP) || pathname.startsWith(APPS),
      ICON: 'fa-th-large',
      URL: URLS.APPS.HOMEPAGE,
    },
    {
      name: 'Services',
      isActive: pathname => pathname.startsWith(SERVICE) || pathname.startsWith(SERVICES),
      ICON: 'fa-cog',
      URL: URLS.SERVICES.HOMEPAGE,
    },
    {
      name: 'Resources',
      isActive: pathname => pathname.startsWith(RESOURCE) || pathname.startsWith(RESOURCES),
      ICON: 'fa-folder',
      URL: URLS.RESOURCES.HOMEPAGE,
    },
    {
      name: 'Recommend a Partner',
      isActive: pathname => pathname.startsWith(REQUESTED_PRODUCT),
      ICON: 'fa-star',
      URL: URLS.REQUESTED_PRODUCTS.HOMEPAGE,
    },
    {
      name: 'Become a Partner',
      isActive: pathname => pathname.startsWith(BECOME_PARTNER),
      ICON: 'fa-user',
      URL: URLS.BECOME_PARTNER.HOMEPAGE,
    },
    {
      name: 'Today',
      isActive: pathname => pathname === URLS.DOCTORS.HOMEPAGE || pathname.startsWith(NETWORK),
      ICON: 'fa-users',
      URL: URLS.DOCTORS.HOMEPAGE,
    },
  ]

  return (
    <ul className={s.navigation}>
      {navigation.map(({ name, isActive, ICON, URL }) => (
        <li
          key={name}
          className={cn(s.navigationItem, { [s.navigationItemActive]: isActive(pathname) })}
        >
          <Link to={URL}>
            <i className={cn('fa', ICON)} />
            {name}
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default MainNavigation
