import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import cn from 'classnames'
import { useLocation, useRouteMatch, Redirect } from 'react-router-dom'
import { loader as gqlLoader } from 'graphql.macro'
import { useQuery } from '@apollo/client'

import genericAvatar from 'assets/images/generic-avatar.png'
import { userNetworkPath, URLS } from 'config/urls'
import Loader from 'components/Common/Loader'
import Breadcrumb from 'components/Common/Breadcrumb'
import { CardsList, Card } from 'components/Common/Card'
import HomepageSidebar from 'components/Product/HomepageSidebar'
import { GENDERS, TITLES, US_STATES, DOCTOR_SPECIALTIES } from 'utils/constants'
import ScreenResolutionContext from 'utils/ScreenResolutionContext'
import withErrorHandler from 'HOC/withErrorHandler'
import s from './NetworkHomepage.module.css'

const DOCTORS = gqlLoader('graphql/queries/Doctors.graphql')

const NetworkHomepage = () => {
  const location = useLocation()
  const match = useRouteMatch()
  const pageSize = 20

  const { fetchMore, loading, data } = useQuery(DOCTORS, {
    variables: {
      uuid: match.params.uuid,
      pageSize,
    },
  })
  const screenResolution = useContext(ScreenResolutionContext)

  return (
    <div className="page-container">
      {loading ? (
        <Loader />
      ) : data ? (
        <>
          {screenResolution.desktop && <HomepageSidebar />}
          <div className="page-content">
            <Helmet>
              <title>DrChrono Doctors Network</title>
              <meta name="description" content={`DrChrono Doctors Network`} />
              <link rel="canonical" href={`https://partners.drchrono.com${location.pathname}`} />
            </Helmet>
            <Breadcrumb routeLabel="Network" />
            {loading ? (
              <Loader />
            ) : (
              <div className="page-content-margin">
                <CardsList
                  className={s.doctors}
                  infiniteScroll={{
                    pageStart: 0,
                    loadMore: async () => {
                      await fetchMore({
                        variables: {
                          cursor: data.doctors.pageInfo.endCursor,
                          pageSize,
                        },
                        updateQuery: (prev, { fetchMoreResult }) => {
                          if (!fetchMoreResult) return prev
                          return Object.assign({}, prev, {
                            doctors: {
                              ...fetchMoreResult.doctors,
                              edges: [...prev.doctors.edges, ...fetchMoreResult.doctors.edges],
                            },
                          })
                        },
                      })
                    },
                    hasMore: data.doctors.pageInfo.hasNextPage,
                  }}
                >
                  {data.doctors.edges.map(({ node: doctor }) => {
                    const profile = doctor.profile
                    return (
                      <Card key={doctor.uuid} className={s.doctor} linkTo={userNetworkPath(doctor)}>
                        <div
                          className={s.doctorAvatar}
                          style={{
                            backgroundImage: `url("${profile.avatar || genericAvatar}")`,
                          }}
                        ></div>
                        <div className={s.doctorInfo}>
                          <div className={cn(s.doctorInfoItem, s.doctorInfoName)}>
                            {`${doctor.firstName} ${doctor.lastName}`}
                          </div>
                          {profile.title && (
                            <div className={s.doctorInfoItem}>
                              {TITLES.find(({ enumValue }) => enumValue === profile.title).label}
                            </div>
                          )}
                          {profile.gender && (
                            <div className={s.doctorInfoItem}>
                              {GENDERS.find(({ enumValue }) => enumValue === profile.gender).label}
                            </div>
                          )}
                          {profile.specialty && (
                            <div className={s.doctorInfoItem}>
                              {
                                DOCTOR_SPECIALTIES.find(
                                  ({ enumValue }) => enumValue === profile.specialty,
                                ).label
                              }
                            </div>
                          )}
                          {profile.state && (
                            <div className={s.doctorInfoItem}>
                              {`${US_STATES.find(state => state.value === profile.state).label} / ${
                                profile.city
                              }`}
                            </div>
                          )}
                        </div>
                      </Card>
                    )
                  })}
                </CardsList>
              </div>
            )}
          </div>
        </>
      ) : (
        <Redirect to={URLS.APPS.HOMEPAGE} />
      )}
    </div>
  )
}

export default withErrorHandler(NetworkHomepage)
