import { useState, useEffect } from 'react'

export default () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    /* Add event listener for window resize in order to update the values when
    the resolution changes */
    window.addEventListener('resize', handleResize)

    /* Initialize the values with the real values */
    handleResize()

    /* Cleanup handlers when unmounting the component */
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
