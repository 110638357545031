import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Alert, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { useFormik } from 'formik'
import Yup from 'yup'
import Select from 'react-select'
import { loader as gqlLoader } from 'graphql.macro'
import { useMutation } from '@apollo/client'
import cn from 'classnames'

import genericAvatar from 'assets/images/generic-avatar.png'
import { userNetworkPath, URLS } from 'config/urls'
import FormikField from 'components/Blocks/FormikField'
import Breadcrumb from 'components/Common/Breadcrumb'
import ServerErrors from 'components/Common/ServerErrors'
import SubmitButton from 'components/Common/SubmitButton'
import ImageUpload from 'components/Common/ImageUpload'
import CoverUpload from 'components/Common/CoverUpload'
import { phoneRegex, GENDERS, TITLES, US_STATES, DOCTOR_SPECIALTIES } from 'utils/constants'

import s from './UserProfile.module.css'
import sAddLead from 'components/Product/AddLead.module.css'

const UPDATE_USER = gqlLoader('graphql/mutations/UpdateUser.graphql')
const MIN_WIDTH = 50
const MIN_HEIGHT = 50

const UserProfile = ({ currentUser }) => {
  const [success, setSuccess] = useState(false)
  const [serverErrors, setServerErrors] = useState(null)
  const [imagePreviewUrl, setImagePreviewUrl] = useState('')
  const [imageError, setImageError] = useState(false)

  const [updateUser] = useMutation(UPDATE_USER)
  const { profile } = currentUser

  const onSubmit = async variables => {
    setSuccess(false)
    try {
      const response = await updateUser({ variables })
      if (response.data.updateUser.errors) {
        setServerErrors(response.data.updateUser.errors)
      } else {
        setSuccess(true)
      }
    } catch (error) {
      setServerErrors(error.graphQLErrors)
    }
  }

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      officePhone: profile.officePhone || '',
      cellPhone: profile.cellPhone || '',
      homePhone: profile.homePhone || '',
      isPublicOfficePhone: profile.isPublicOfficePhone,
      isPublicCellPhone: profile.isPublicCellPhone,
      isPublicHomePhone: profile.isPublicHomePhone,
      title: profile.title && TITLES.find(({ enumValue }) => enumValue === profile.title).value,
      gender: profile.gender && GENDERS.find(({ enumValue }) => enumValue === profile.gender).value,
      specialty:
        profile.specialty ||
        DOCTOR_SPECIALTIES.find(({ enumValue }) => enumValue === profile.specialty).value,
      state: profile.state,
      city: profile.city,
      address: profile.address,
      linkedin: profile.linkedin,
      website: profile.website,
      bio: profile.bio,
      coverColor: profile.coverColor,
      hideInterestedProducts: profile.hideInterestedProducts,
      image: '',
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      officePhone: Yup.string().matches(phoneRegex, { message: 'Invalid Phone Number' }),
      cellPhone: Yup.string().matches(phoneRegex, { message: 'Invalid Phone Number' }),
      homePhone: Yup.string().matches(phoneRegex, { message: 'Invalid Phone Number' }),
      address: Yup.string(),
      linkedin: Yup.string().url('URL is invalid'),
      website: Yup.string().url('URL is invalid'),
      image: Yup.string(),
      coverColor: Yup.string(),
    }),
    // eslint-disable-next-line
    onSubmit: (values, { setSubmitting }) => {
      onSubmit(values).then(() => {
        setSubmitting(false)
      })
    },
  })

  const handleImageChange = image => {
    const { width, height } = image

    const previewUrl = image.src
    setImagePreviewUrl(previewUrl)
    setImageError(width < MIN_WIDTH || height < MIN_HEIGHT)
    setFieldValue('image', previewUrl)
  }

  return (
    <div className="page-container">
      <div className="page-content full">
        <Breadcrumb
          routePath={URLS.APPS.HOMEPAGE}
          routeLabel="Browse Marketplace"
          backLink={true}
        />
        <Form onSubmit={handleSubmit} className={s.form}>
          <Row form>
            <Col lg={4} md={5} sm={12}>
              <div className={cn(s.profileCard, 'card')}>
                <div className={s.cover} style={{ backgroundColor: profile.coverColor }}></div>
                <div className={s.formAvatar}>
                  <img
                    alt="profile"
                    className={s.formAvatarImage}
                    src={imagePreviewUrl || profile.avatar || genericAvatar}
                  />
                  {imageError && `Image must be ast least ${MIN_WIDTH}px by ${MIN_HEIGHT}px`}
                  <ImageUpload onChange={handleImageChange} className={s.formAvatarUpload} />
                  <CoverUpload
                    onChange={color => setFieldValue('coverColor', color)}
                    className={s.formCoverUpload}
                  />
                  <NavLink to={userNetworkPath(currentUser)} className={s.publicProfileLink}>
                    View public Profile
                  </NavLink>
                </div>
              </div>
            </Col>
            <Col lg={8} md={7} sm={12}>
              <div className={cn(s.formCard, 'card')}>
                <h4>My Profile</h4>
                <Row form>
                  {[
                    ['firstName', 'First Name', 'First Name*'],
                    ['lastName', 'Last Name', 'Last Name*'],
                  ].map(([field, label, placeholder]) => (
                    <Col md={12} lg={6} key={field}>
                      <FormGroup className={s.formGroup}>
                        <Label for={field}>{label}</Label>
                        <FormikField
                          field={field}
                          placeholder={placeholder}
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  ))}
                </Row>
                <Row form>
                  <Col md={12} lg={6}>
                    <FormGroup>
                      <Label for="title">Title</Label>
                      <Select
                        name="title"
                        className={s.formSelect}
                        placeholder={'Select Title'}
                        noOptionsMessage={() => null}
                        options={
                          currentUser.isDrchronoCrmStaff
                            ? TITLES
                            : TITLES.filter(t => t.enumValue !== 'DRCHRONO_EMPLOYEE')
                        }
                        onChange={({ value }) => setFieldValue('title', value)}
                        value={TITLES.find(({ value }) => value === values.title)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={12} lg={6}>
                    <FormGroup>
                      <Label for="gender">Gender</Label>
                      <Select
                        name="gender"
                        className={s.formSelect}
                        placeholder={'Select Gender'}
                        noOptionsMessage={() => null}
                        options={GENDERS}
                        onChange={({ value }) => setFieldValue('gender', value)}
                        value={GENDERS.find(({ value }) => value === values.gender)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={12} lg={6}>
                    <FormGroup>
                      <Label for="specialty">Role</Label>
                      <Select
                        name="specialty"
                        className={s.formSelect}
                        placeholder={'Select Role'}
                        noOptionsMessage={() => null}
                        options={DOCTOR_SPECIALTIES}
                        onChange={({ value }) => setFieldValue('specialty', value)}
                        value={DOCTOR_SPECIALTIES.find(({ value }) => value === values.specialty)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {[
                  ['officePhone', 'isPublicOfficePhone', 'Office Phone'],
                  ['cellPhone', 'isPublicCellPhone', 'Cell Phone'],
                  ['homePhone', 'isPublicHomePhone', 'Home Phone'],
                ].map(([field, isPublic, placeholder], index) => (
                  <Row key={field} form className={s.formPhone}>
                    <Col md={12} lg={6}>
                      <FormGroup className={s.formGroup}>
                        <Label for={field}>{placeholder}</Label>
                        <FormikField
                          field={field}
                          placeholder={placeholder}
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12} lg={6}>
                      <Label check style={{ marginLeft: 20 }}>
                        <Input
                          type="checkbox"
                          checked={values[isPublic]}
                          onChange={() => setFieldValue(isPublic, !values[isPublic])}
                        />{' '}
                        Make your {placeholder} public?
                      </Label>
                    </Col>
                  </Row>
                ))}
                <br />
                <Row form>
                  <Col md={12} lg={6}>
                    <FormGroup className={s.formGroup}>
                      <Label for="state">State</Label>
                      <Select
                        name="state"
                        className={cn({
                          [sAddLead.stateSelectError]: errors.state && touched.state,
                        })}
                        placeholder="Select state*"
                        value={US_STATES.find(state => state.value === values.state)}
                        onChange={state => setFieldValue('state', state.value)}
                        options={US_STATES}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12} lg={6}>
                    <FormGroup className={s.formGroup}>
                      <Label for="city">City</Label>
                      <FormikField
                        field="city"
                        placeholder="City"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} lg={6}>
                    <FormGroup className={s.formGroup}>
                      <Label for="address">Address</Label>
                      <FormikField
                        field="address"
                        placeholder="Address"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row form>
                  <Col md={12} lg={6}>
                    <FormGroup className={s.formGroup}>
                      <Label for="linkedin">Linkedin Profile URL</Label>
                      <FormikField
                        field="linkedin"
                        placeholder="Linkedin Profile URL"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Row form>
                  <Col md={12} lg={6}>
                    <FormGroup className={s.formGroup}>
                      <Label for="website">Website URL</Label>
                      <FormikField
                        field="website"
                        placeholder="Website URL"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={12} lg={6}>
                    <FormGroup className={s.formGroup}>
                      <Label for="website">Bio</Label>
                      <FormikField
                        field="bio"
                        type="textarea"
                        placeholder="Bio"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col md={12} lg={6}>
                    <Label check style={{ marginLeft: 20 }}>
                      <Input
                        type="checkbox"
                        checked={values.hideInterestedProducts}
                        onChange={() =>
                          setFieldValue('hideInterestedProducts', !values.hideInterestedProducts)
                        }
                      />{' '}
                      Hide apps you are interested in from your public profile?
                    </Label>
                  </Col>
                </Row>
                <br />
                <ServerErrors errors={serverErrors} />
                {success && <Alert color="success">Profile Successfully Updated</Alert>}
              </div>
            </Col>
          </Row>
          <SubmitButton value="Save" loading={isSubmitting} className={s.formSubmitButton} />
        </Form>
      </div>
    </div>
  )
}

export default UserProfile
