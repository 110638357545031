import React, { useState } from 'react'
import querystring from 'query-string'
import { loader as gqlLoader } from 'graphql.macro'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import Login from 'components/Accounts/Login'
import { URLS } from 'config/urls'
import { authenticate, isDrChronoAuthenticated, deleteIsSocialUser } from 'utils/authService'

const LOGIN = gqlLoader('graphql/mutations/Login.graphql')

const LoginContainer = () => {
  const history = useHistory()
  const location = useLocation()

  const [login, { loading }] = useMutation(LOGIN)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState(null)

  const { token } =
    (location.state && querystring.parse(location.state.from.search)) ||
    querystring.parse(history.location.search)

  if (isDrChronoAuthenticated()) {
    history.push(URLS.APPS.HOMEPAGE)
  } else if (token) {
    deleteIsSocialUser()
    authenticate(token)
    history.push(URLS.APPS.HOMEPAGE)
  }

  const onSubmit = async () => {
    try {
      const response = await login({
        variables: { username, password },
      })

      const {
        data: {
          login: { token, errors },
        },
      } = response

      if (errors) {
        setErrors(errors)
      } else {
        authenticate(token)
        return true
      }
    } catch (error) {
      setErrors(error.graphQLErrors.map(x => x.message))
    }
  }

  const handleChange = (value, name) => {
    if (name === 'username') {
      setUsername(value)
    } else {
      setPassword(value)
    }
  }

  return (
    <Login
      serverErrors={errors}
      username={username}
      password={password}
      onChange={handleChange}
      onSubmit={onSubmit}
      isSubmitting={loading}
    />
  )
}

export default LoginContainer
