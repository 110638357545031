import React from 'react'

const Loader = () => (
  <div
    className="d-flex justify-content-center align-self-center align-content-center pt-5 pb-5"
    style={{ width: '100%' }}
  >
    <div className="mt-5 mb-5 fa fa-spinner fa-spin fa-3x" />
  </div>
)

export default Loader
