import React from 'react'
import { FormFeedback, Input as I } from 'reactstrap'

import ServerErrors from 'components/Common/ServerErrors'

const Input = ({ name, onChange, errors, touched, serverErrors, ...rest }) => {
  const fieldServerErrors = serverErrors && serverErrors.filter(e => e.field === name)
  return (
    <>
      <I
        invalid={
          (fieldServerErrors && fieldServerErrors.length > 0) || (errors[name] && touched[name])
        }
        onChange={e => onChange(e.target.value, e.target.name)}
        name={name}
        {...rest}
      />
      {errors[name] && touched[name] && <FormFeedback>{errors[name]}</FormFeedback>}
      <ServerErrors errors={serverErrors} field={name} />
    </>
  )
}

export default Input
