import React, { useState } from 'react'
import { NavLink as Link } from 'react-router-dom'
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { loader as gqlLoader } from 'graphql.macro'
import { useApolloClient } from '@apollo/client'

import genericAvatar from 'assets/images/generic-avatar.png'
import { IS_DEV } from 'config/config'
import { URLS } from 'config/urls'
import DrChronoLoginButton from 'components/Base/DrChronoLoginButton'
import { isDrChronoAuthenticated, isSocialAuthenticated } from 'utils/authService'

import s from './Header.module.css'

const CURRENT_USER = gqlLoader('graphql/queries/CurrentUser.graphql')

const HeaderLinks = ({ oauthDenied, isMobile, toggleMobileMenu }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const client = useApolloClient()
  let currentUser
  if (isDrChronoAuthenticated()) {
    currentUser = client.readQuery({ query: CURRENT_USER }).currentUser
  }

  const toggle = () => setIsDropdownOpen(!isDropdownOpen)

  const onMouseEnter = () => setIsDropdownOpen(true)

  const onMouseLeave = () => setIsDropdownOpen(false)

  const renderAccountInformation = () => {
    return (
      <span className={s.accountInformation}>
        <img height={38} src={currentUser.profile.avatarThumbnail || genericAvatar} alt="Avatar" />
        <span>{currentUser.username}</span>
      </span>
    )
  }

  return (
    <ul className={isMobile ? s.navBarMobile__nav : s.navBar}>
      {currentUser && (
        <>
          <li className={isMobile ? s.navBarMobile__item : s.navItem}>
            {!isMobile ? (
              <UncontrolledDropdown
                onMouseOver={onMouseEnter}
                onMouseLeave={onMouseLeave}
                isOpen={isDropdownOpen}
                toggle={toggle}
              >
                <DropdownToggle className={s.navDropDownToggle}>
                  {renderAccountInformation()}
                </DropdownToggle>
                <DropdownMenu className={s.DropdownMenu}>
                  <DropdownItem className={s.DropdownItem}>
                    <Link to={URLS.MY_PROFILE}>My Profile</Link>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem className={s.DropdownItem}>
                    <Link to={URLS.AUTH.LOGOUT}>
                      <i className="fa fa-sign-out" />
                      Log Out
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              renderAccountInformation()
            )}
          </li>
          <li className={isMobile ? s.navBarMobile__item : s.navItem}>
            <Link className={isMobile ? s.mobileLink : s.buttonLink} to={URLS.DEV.APPS}>
              My Account
            </Link>
          </li>
          {isMobile && (
            <>
              <li className={s.navBarMobile__item}>
                <Link className={s.mobileLink} to={URLS.MY_PROFILE}>
                  My Profile
                </Link>
              </li>
              <li className={s.navBarMobile__item}>
                <Link className={s.mobileLink} to={URLS.AUTH.LOGOUT}>
                  Log Out
                </Link>
              </li>
            </>
          )}
        </>
      )}
      {!currentUser && (
        <>
          {oauthDenied && (
            <li className="text-danger">
              <small>Authentication request denied, please try again.</small>
            </li>
          )}
          <li className={isMobile ? s.navBarMobile__item : s.navItem}>
            <DrChronoLoginButton />
          </li>
          {IS_DEV && (
            <li className={isMobile ? s.navBarMobile__item : s.navItem}>
              <Link className={s.buttonLink} to={URLS.AUTH.LOGIN} onClick={toggleMobileMenu}>
                Log In
              </Link>
            </li>
          )}
        </>
      )}
      {isSocialAuthenticated() && (
        <>
          <li className={isMobile ? s.navBarMobile__item : s.navItem}>
            <Link className={isMobile ? s.mobileLink : s.buttonLink} to={URLS.AUTH.LOGOUT}>
              Log Out
            </Link>
          </li>
        </>
      )}
    </ul>
  )
}

export default HeaderLinks
