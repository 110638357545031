import React from 'react'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

import DrChronoLoginButton from 'components/Base/DrChronoLoginButton'
import FacebookLoginButton from 'components/Base/FacebookLoginButton'

const MustLoginDialog = ({ message, onClose, includeSocials }) => {
  return (
    <Modal isOpen>
      <ModalBody>
        <div className="text-center mt-3">{message || 'You need to login.'}</div>
        <div className="text-center mt-3">
          <DrChronoLoginButton />
        </div>
        {includeSocials && (
          <div className="text-center mt-3">
            <FacebookLoginButton />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default MustLoginDialog
