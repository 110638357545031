import React from 'react'
import { Alert, FormFeedback } from 'reactstrap'

const E = {
  USER_WRONG_USERNAME_OR_PASSWORD: 'Username does not exist or password is wrong',
  USER_USERNAME_ALREADY_EXISTS: 'Username already exists',
  USER_WRONG_PHONE: 'Phone Number is not a correct phone number',
  USER_EMAIL_ALREADY_EXISTS: 'A User with this email has already logged in',
  USER_SIGNATURE_EMPTY: 'Please read and sign the document',
  USER_EMAIL_DOES_NOT_EXIST: 'This email does not exist',
}

const ServerErrors = ({ errors, field }) => {
  if (!errors || !errors.length) return <></>
  return (
    <>
      {errors
        .filter(e => !field || e.field === (field || null))
        .map(e =>
          field ? (
            <FormFeedback key={e.error || e.message}>
              {E[e.error] || e.error || e.message}
            </FormFeedback>
          ) : (
            <Alert color="danger" key={e.error || e.message} style={{ marginTop: '10px' }}>
              {E[e.error] || e.error || e.message}
            </Alert>
          ),
        )}
    </>
  )
}
export default ServerErrors
