import React, { useState, useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Collapse } from 'reactstrap'
import Select from 'react-select'
import { loader as gqlLoader } from 'graphql.macro'
import cn from 'classnames'
import { useQuery } from '@apollo/client'
import Loader from 'components/Common/Loader'
import MainNavigation from 'components/Base/MainNavigation'
import StaticRadioInput from 'components/Common/StaticRadioInput'
import s from 'components/Product/HomepageSidebar.module.css'
import SpotifyiFrame from 'components/Spotify/SpotifyiFrame'
import { categoryPath } from 'config/urls'
import withErrorHandler from 'HOC/withErrorHandler'
import { PLATFORMS, PRODUCT_TYPE, SPECIALTIES } from 'utils/constants'
import ScreenResolutionContext from 'utils/ScreenResolutionContext'

const GENERIC = gqlLoader('graphql/queries/Generic.graphql')

const Category = ({ selectedCategory, category, setOpen }) => {
  const isActive = selectedCategory === category
  const [collapse, setCollapse] = useState(isActive)
  const { pathname } = useLocation()

  const closeSidebar = () => {
    if (setOpen) {
      setOpen(false)
    }
  }

  return !category.subcategories?.length ? (
    <div className={s.sidebarCategoryItem}>
      <NavLink
        to={categoryPath(category, pathname)}
        activeClassName={s.active}
        onClick={closeSidebar}
      >
        {category.name}
      </NavLink>
    </div>
  ) : (
    <>
      <div
        className={s.sidebarCategoryItem}
        role="link"
        tabIndex="0"
        onClick={() => setCollapse(!collapse)}
      >
        {category.name}
        <span className={s.sidebarCategoryItemDrawer}>
          {collapse ? <i className="fa fa-angle-up" /> : <i className="fa fa-angle-down" />}
        </span>
      </div>
      <Collapse isOpen={collapse}>
        <div className={s.sidebarSubCategory}>
          {category.subcategories.map(subcategory => (
            <div key={subcategory.uuid} className={s.sidebarSubCategoryItem}>
              <NavLink
                activeClassName={s.active}
                to={categoryPath(subcategory, pathname)}
                onClick={closeSidebar}
              >
                <StaticRadioInput checked={categoryPath(subcategory, pathname) === pathname} />
                {subcategory.name}
              </NavLink>
            </div>
          ))}
          <hr className={s.sidebarSubCategorySpacer} />
          <div className={s.sidebarSubCategoryItem}>
            <NavLink to={categoryPath(category, pathname)} activeClassName={s.active}>
              View All
            </NavLink>
          </div>
        </div>
      </Collapse>
    </>
  )
}

const HomepageSidebar = ({
  productType,
  staffPickCategories,
  categories,
  platforms,
  setPlatforms,
  specialties,
  setSpecialties,
  isMobile,
  ...rest
}) => {
  const screenResolution = useContext(ScreenResolutionContext)
  const { loading, data } = useQuery(GENERIC)

  return (
    <div className={cn(s.sidebar, { [s.sidebarMobile]: !!isMobile })}>
      {/* If not on mobile, the Header Navigation should reside in the Homepage Sidebar, at the top */}
      {screenResolution.desktop && (
        <div className={cn(s.sidebarCategory, s.navigationSection)}>
          <MainNavigation />
        </div>
      )}
      {!!staffPickCategories?.length && (
        <div className={s.sidebarCategory}>
          <h6>Staff Picks</h6>
          {staffPickCategories.map(c => (
            <Category key={c.uuid} category={c} {...rest} />
          ))}
        </div>
      )}
      {!!categories?.length && (
        <div className={s.sidebarCategory}>
          <h6>Categories</h6>
          {categories.map(c => (
            <Category key={c.uuid} category={c} {...rest} />
          ))}
        </div>
      )}
      {productType && (
        <div className={s.sidebarCategory}>
          <h6>Filter by Specialties</h6>
          <Select
            name="specialties"
            isMulti
            placeholder={'Select specialties'}
            noOptionsMessage={() => null}
            options={SPECIALTIES.map(s => ({ label: s, value: s }))}
            onChange={specialties => {
              setSpecialties((specialties || []).map(s => s.value))
            }}
            value={specialties.map(s => ({ label: s, value: s }))}
          />
        </div>
      )}
      {productType === PRODUCT_TYPE.APP && (
        <div className={s.sidebarCategory}>
          <h6>Filter by Platform</h6>
          {PLATFORMS.map(p => (
            <div
              role="button"
              tabIndex="0"
              key={p.value}
              className={cn(
                s.sidebarCategoryItem,
                s.platform,
                platforms[p.value] ? s.selected : '',
              )}
              onClick={() =>
                setPlatforms({
                  ...platforms,
                  [p.value]: !platforms[p.value],
                })
              }
            >
              <div className={s.platformTitle}>{p.label}</div>
            </div>
          ))}
        </div>
      )}
      {loading ? (
        <Loader />
      ) : (
        data.generic?.spotifyPodcastId && (
          <div className={s.section}>
            <SpotifyiFrame spotifyPodcastId={data.generic.spotifyPodcastId} />
          </div>
        )
      )}
    </div>
  )
}

export default withErrorHandler(HomepageSidebar)
