import React from 'react'
import cn from 'classnames'

import s from './LoadingGif.module.css'

const LoadingGif = ({ loading }) => (
  <i
    className={cn('fa fa-spinner fa-spin', s.spinner, {
      'd-inline': loading,
      'd-none': !loading,
    })}
  />
)

export default LoadingGif
