import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import s from './Breadcrumb.module.css'

const Breadcrumb = ({routeLabel, routePath, subrouteLabel, backLink, action}) => {
	if (!routeLabel) {
		return null;
	}

  return (
		<div className={s.breadcrumb}>
			<div className={s.content}>
				<div className={cn(s.route, {[s.routeBack]: !!backLink})}>
					{
						!routePath ? <span className={s.routePath}>{routeLabel}</span> :
						<>
							{backLink && <i className="fa fa-angle-left" />}
							<NavLink className={s.routePath} to={routePath}>{routeLabel}</NavLink>
						</>
					}
					{subrouteLabel &&
						<div className={s.routeSeparator}>/</div>
					}
				</div>
				{subrouteLabel && 
					<div className={s.subroute}>{subrouteLabel}</div>
				}
			</div>
			{action && <div className={s.action}>{action}</div>}
		</div>
  )
}

export default Breadcrumb
