import React from 'react'
import * as Sentry from '@sentry/browser'

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error) {
    Sentry.captureException(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          Oops, something went wrong! If you need to access this page right away trying refreshing or
          <a href="mailto:contact@drchrono.com">&nbsp;let us know.</a>
        </div>
      )
    }

    return this.props.children
  }
}

export default function withErrorHandler(Component) {
  function withHandler(props) {
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    )
  }
  return withHandler
}
