import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const ScrollToTop = ({ children }) => {
  const history = useHistory()
  const location = useLocation()
  history.scrollRestoration = 'manual'

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return children
}

export default ScrollToTop
