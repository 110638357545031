import React from 'react'
import LazyLoad from 'react-lazyload'

const SpotifyiFrame = ({ spotifyPodcastId }) => (
  <LazyLoad height={155}>
    <div>
      <iframe
        defer
        title="podcast"
        src={`https://open.spotify.com/embed/episode/${spotifyPodcastId}`}
        width="100%"
        height="155"
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
      />
    </div>
  </LazyLoad>
)

export default SpotifyiFrame
