import React, { useState } from 'react'
import { CirclePicker } from 'react-color'
import { Button, Popover, PopoverBody } from 'reactstrap'
import cn from 'classnames'

import { COVER_COLORS } from 'utils/constants'

import s from './CoverUpload.module.css'

const CoverUpload = ({ onChange: onChangeCallback, className }) => {
  const [popoverOpen, setPopoverOpen] = useState(false)

  const toggle = () => setPopoverOpen(!popoverOpen)

  const onChange = color => {
    const chosenValue = Object.entries(COVER_COLORS).find(
      ([, colorValue]) => colorValue.toUpperCase() === color.hex.toUpperCase(),
    )
    if (chosenValue) {
      onChangeCallback(chosenValue[0])
    }

    toggle()
  }

  return (
    <div className={cn(s.coverUpload, className)}>
      <Button
        className={cn(s.triggerButton, { [s.triggerButtonActive]: popoverOpen })}
        id="color-picker-trigger"
        type="button"
      >
        <i className="fa fa-tint" />
        Choose cover image color
      </Button>
      <Popover
        trigger="legacy"
        placement="bottom"
        isOpen={popoverOpen}
        target="color-picker-trigger"
        toggle={toggle}
      >
        <PopoverBody>
          <CirclePicker onChangeComplete={onChange} colors={Object.values(COVER_COLORS)} />
        </PopoverBody>
      </Popover>
    </div>
  )
}

export default CoverUpload
