import React from 'react'

import signInLogo from 'assets/images/sign-in-with-facebook.png'
import { getBackendEndpoint } from 'config/config'

const FacebookLoginButton = () => {
  return (
    <a href={getBackendEndpoint('/backend/oauth/login/facebook/')}>
      <img alt="Sign In with Facebook" height="76" src={signInLogo} />
    </a>
  )
}

export default FacebookLoginButton
