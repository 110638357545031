import React from 'react'
import { useLocation } from 'react-router-dom'

import { setLoginRedirectUrl } from 'utils/authService'
import signInLogo from 'assets/images/sign-in-with-drchrono.png'
import { DRCHRONO_AUTHORIZE_URL } from 'config/config'

const DrChronoLoginButton = () => {
  const { pathname } = useLocation()

  return (
    <a href={DRCHRONO_AUTHORIZE_URL} onClick={() => setLoginRedirectUrl(pathname)}>
      <img alt="Sign In with DrChrono" height="38" src={signInLogo} />
    </a>
  )
}

export default DrChronoLoginButton
