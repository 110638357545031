import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Autocomplete from 'react-autocomplete'
import { useQuery } from '@apollo/client'
import { loader as gqlLoader } from 'graphql.macro'
import cn from 'classnames'

import s from 'components/Common/Search.module.css'
import Error from 'components/Common/Error'
import Loader from 'components/Common/Loader'
import genericItem from 'assets/images/generic-product.png'
import genericAvatar from 'assets/images/generic-avatar.png'
import { URLS, productPath, userNetworkPath } from 'config/urls'
import { truncate } from 'utils'
import { PRODUCT_TYPE, TITLES } from 'utils/constants'

const SEARCH = gqlLoader('graphql/queries/Search.graphql')

const Search = () => {
  let timer
  const SEARCH_MIN_LENGTH = 2
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [doSearch, setDoSearch] = useState(false)
  const { loading, error, data } = useQuery(SEARCH, { variables: { search }, skip: !doSearch })

  useEffect(() => {
    const input = document.getElementById('item-search-input')

    document.addEventListener('click', event => {
      /** Close the autocomplete if click outside search input or any of menu's items. */
      if (event.target !== input) {
        setIsOpen(false)
      }
    })

    /** Reopen the menu on input focus. */
    input.addEventListener('focus', () => {
      setIsOpen(true)
    })
  }, [])

  const onClear = () => {
    setIsOpen(false)
    setSearch('')
  }

  const onSelect = (search, item) => {
    onClear()
    let url = `${
      item.productType === PRODUCT_TYPE.APP ? URLS.APPS.HOMEPAGE : URLS.SERVICES.HOMEPAGE
    }?search=${search}`
    if (item.name === 'Network') {
      url = URLS.DOCTORS.HOMEPAGE
    } else if (item.__typename === 'UserNode') {
      url = userNetworkPath(item)
    } else {
      url = productPath(item)
    }
    history.push(url)
  }

  const onChange = e => {
    setSearch(e.target.value)
    setIsOpen(true)
    clearTimeout(timer)

    if (e.target.value.length >= SEARCH_MIN_LENGTH) {
      timer = setTimeout(() => setDoSearch(true), 1000)
    } else {
      setDoSearch(false)
    }
  }

  if (doSearch && error) return <Error message={error.message} />

  let resultItems = []
  if (!loading && doSearch) {
    if (data.search.products.length > 0) {
      resultItems.push({
        header: true,
        name: 'Apps and Services',
      })
      resultItems = [...resultItems, ...data.search.products]
    }
    if (data.search.users.length > 0) {
      resultItems.push({
        header: true,
        name: 'Network Profiles',
      })
      resultItems = [...resultItems, ...data.search.users]
    }
  }
  return (
    <Autocomplete
      open={isOpen}
      wrapperProps={{
        className: s.searchWrapper,
      }}
      inputProps={{
        id: 'item-search-input',
        name: 'search',
        placeholder: 'Search',
        className: cn('form-input form-control', s.searchInput),
      }}
      getItemValue={item => item.name}
      items={resultItems}
      renderItem={(item, isHighlighted) => {
        if (item.header) {
          return (
            <div key={item.name} className={cn(s.searchItem, s.searchItemHeader)}>
              {item.name}
            </div>
          )
        }
        if (item.__typename === 'UserNode') {
          return (
            <div
              key={item.uuid}
              className={cn(s.searchItem, { [s.searchItemHighlighted]: isHighlighted })}
            >
              <img
                className={s.searchItemThumbnail}
                src={item.profile.avatarThumbnail || genericAvatar}
                alt="Doctor thumbnail"
              />
              <div className={s.searchItemBody}>
                <div className={s.searchItemTitle}>
                  {item.firstName} {item.lastName}
                </div>
                <div className={s.searchItemSummary}>
                  {item.profile.title && (
                    <div>
                      {TITLES.find(({ enumValue }) => enumValue === item.profile.title).label}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div
              key={item.uuid}
              className={cn(s.searchItem, { [s.searchItemHighlighted]: isHighlighted })}
            >
              <img
                className={s.searchItemThumbnail}
                src={item.logoThumbnail || genericItem}
                alt="Product thumbnail"
              />
              <div className={s.searchItemBody}>
                <div className={s.searchItemTitle}>{item.name}</div>
                <div className={s.searchItemSummary}>{truncate(item.summary)}</div>
                <div>{item.productType === PRODUCT_TYPE.APP ? '(app)' : '(service)'}</div>
              </div>
            </div>
          )
        }
      }}
      // eslint-disable-next-line
      renderMenu={(items, value, style) => (
        <div className={s.menu}>
          {doSearch && loading ? (
            <Loader />
          ) : items.length === 0 && search.length >= SEARCH_MIN_LENGTH && doSearch ? (
            <div
              className={cn(s.searchItem, s.searchItemHighlighted, s.noResults)}
              onClick={() => {
                onClear()
                history.push(URLS.REQUESTED_PRODUCTS.HOMEPAGE)
              }}
            >
              Hmm, it doesn't look like we can find what you are looking for.
            </div>
          ) : (
            items
          )}
        </div>
      )}
      value={search}
      onChange={onChange}
      // eslint-disable-next-line
      onSelect={(value, item) => onSelect(search, item)}
    />
  )
}

export default Search
