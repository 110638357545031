import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { from } from 'apollo-link'
import { onError } from 'apollo-link-error'

import { GRAPHQL_ENDPOINT } from 'config/config'
import introspectionQueryResultData from 'fragmentTypes.json'
import { getToken, logout } from 'utils/authService'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

// eslint-disable-next-line no-unused-vars
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken()
  if (token) {
    // return the headers to the context so httpLink can read them
    const Authorization = `Bearer ${token}`
    return { headers: { ...headers, Authorization } }
  }
  return { headers }
})

const httpLink = new HttpLink({ uri: GRAPHQL_ENDPOINT })

const logoutAfterware = onError(({ networkError }) => {
  if (networkError && (networkError.statusCode === 401 || networkError.statusCode === 403)) {
    logout(client)
  }
})

const client = new ApolloClient({
  link: logoutAfterware.concat(from([authLink, httpLink])),
  cache: new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: object => {
      const theId = object.uuid || object.id
      if (theId) {
        return `${object.__typename}-${theId}`
      } else {
        return null
      }
    },
  }),
  connectToDevTools: true,
})

export default client
